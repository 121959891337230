import React, { forwardRef, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { NavLink as RouterLink, withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import {
  AppBar,
  Toolbar,
  Hidden,
  List,
  ListItem,
  ListItemIcon,
  Popover,
  Popper,
  Paper,
  Typography,
  IconButton,
  colors,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MenuIcon from '@material-ui/icons/Menu';
import { Image } from 'components/atoms';
import { Translate } from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: '1px',
    background: theme.palette.white,
    borderBottom: `0px solid ${colors.grey[200]}`,
  },
  flexGrow: {
    flexGrow: 1,
  },
  navigationContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  toolbarUs: {
    maxWidth: 1280,
    width: '100%',
    margin: '0 auto',
    padding: theme.spacing(0, 2),
    minHeight: 40,
    height: 40,
  },
  toolbar: {
    maxWidth: 1280,
    width: '100%',
    height : 60,
    margin: '0 auto',
    padding: theme.spacing(0, 2),

    [theme.breakpoints.up('md')]: {
      height : 80
    }
  },
  navLink: {
    fontWeight: 400,
    '&:hover': {
      color: theme.palette.primary.dark,
    },
  },
  listItem: {
    cursor: 'pointer',
    '&:hover > .menu-item, &:hover svg': {
      color: theme.palette.primary.dark,
    },
  },
  listItemActive: {
    '&> .menu-item': {
      color: theme.palette.primary.dark,
    },
  },
  listItemTextUs: {
    flex: '0 0 auto',
    marginRight: theme.spacing(1),
    whiteSpace: 'nowrap',
  },
  listItemText: {
    flex: '0 0 auto',
    marginRight: theme.spacing(1),
    whiteSpace: 'nowrap',
    fontWeight: 600,
  },
  listItemIcon: {
    minWidth: 'auto',
  },
  popover: {
    padding: theme.spacing(2, 4),
    border: theme.spacing(2),
    boxShadow: '0 0.5rem 2rem 2px rgba(116, 123, 144, 0.09)',
    minWidth: 250,
    marginTop: theme.spacing(2),
    borderTop: `2px solid ${theme.palette.primary.main}`,
  },
  iconButton: {
    padding: 0,
    '&:hover': {
      background: 'transparent',
    },
  },
  expandOpen: {
    transform: 'rotate(180deg)',
    color: theme.palette.primary.dark,
  },
  logoContainer: {
    width: 172,
    height: 40,
    [theme.breakpoints.up('md')]: {
      width: 215,
      height: 50,
    },
  },
  logoContainer_beta: {
    width: 50,
    height: 35,
    [theme.breakpoints.up('md')]: {
      width: 60,
      height: 40,
    },
  },
  logoImage: {
    width: '100%',
    height: '100%',
  },
  menu: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  menuItem: {
    marginRight: theme.spacing(5),
    '&:last-child': {
      marginRight: 0,
    },
  },
  menuGroupItem: {
    paddingTop: 0,
  },
  menuGroupTitle: {
    textTransform: 'uppercase',
  },
}));

const CustomRouterLink = forwardRef((props, ref) => (
  <div ref={ref}>
    <RouterLink {...props} />
  </div>
));

const Topbar = props => {
  const { className, onSidebarOpen, pages, history, staticContext, ...rest } = props;

  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState(null);
  const [openedPopoverId, setOpenedPopoverId] = useState(null);

  const handleClick = (event, popoverId) => {
    setAnchorEl(event.target);
    setOpenedPopoverId(popoverId);
  };

  const handleClickGotoPage = (event, popoverId) => {
    history.push(popoverId);
    handleClose();
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpenedPopoverId(null);
  };

  const howItWorks = pages.howitworks;
  const admissionsPages = pages.admissions;
  const academics = pages.academics;
  const studentLife = pages.studentlife;
  const aboutUs = pages.aboutus;

  // const MenuGroup = props => {
  //   const { item } = props;
  //   return (
  //     <List disablePadding>
  //       <ListItem disableGutters>
  //         <Typography
  //           variant="body2"
  //           color="primary"
  //           className={classes.menuGroupTitle}
  //         >
  //           {item.groupTitle}
  //         </Typography>
  //       </ListItem>
  //       {item.pages.map((page, i) => (
  //         <ListItem disableGutters key={i} className={classes.menuGroupItem}>
  //           <Typography
  //             variant="body1"
  //             component={CustomRouterLink}
  //             to={page.href}
  //             className={clsx(classes.navLink, 'submenu-item')}
  //             color="textSecondary"
  //             onClick={handleClose}
  //           >
  //             {page.title}
  //           </Typography>
  //         </ListItem>
  //       ))}
  //     </List>
  //   );
  // };

  const ETCGroup = props => {
    const { item } = props;
    return (
      <List disablePadding>
        {item.pages.map((page, i) => (
          <ListItem disableGutters key={i} className={classes.menuGroupItem}>
            <Typography
              variant="body1"
              component={CustomRouterLink}
              to={page.href}
              className={clsx(classes.navLink, 'submenu-item')}
              color="textSecondary"
              onClick={handleClose}
            >
              {page.title}
            </Typography>
          </ListItem>
        ))}
      </List>
    );
  };

  const HowItWorks = () => {
    const { etc } = howItWorks.children;
    return (
      <div className={classes.menu}>
        <div className={classes.menuItem}>
          <ETCGroup item={etc} />
        </div>
      </div>
    );
  };

  const AdmissionsPages = () => {
    const { etc } = admissionsPages.children;
    return (
      <div className={classes.menu}>
        <div className={classes.menuItem}>
          <ETCGroup item={etc} />
        </div>
      </div>
    );
  };

  const Academics = () => {
    const { etc } = academics.children;
    return (
      <div className={classes.menu}>
        <div className={classes.menuItem}>
          <ETCGroup item={etc} />
        </div>
      </div>
    );
  };

  const StudentLife = () => {
    const { etc } = studentLife.children;
    return (
      <div className={classes.menu}>
        <div className={classes.menuItem}>
          <ETCGroup item={etc} />
        </div>
      </div>
    );
  };

  const AboutUs = () => {
    const { etc } = aboutUs.children;
    return (
      <div className={classes.menu}>
        <div className={classes.menuItem}>
          <ETCGroup item={etc} />
        </div>
      </div>
    );
  };

  const renderPages = id => {
    if (id === 'howitworks-pages') {
      return <HowItWorks />;
    }
    if (id === 'admissions-pages') {
      return <AdmissionsPages />;
    }
    if (id === 'academics-pages') {
      return <Academics />;
    }
    if (id === 'studentLife-pages') {
      return <StudentLife />;
    }
    if (id === 'aboutUs-pages') {
      return <AboutUs />;
    }
  };

  return (
    <React.Fragment>
    <AppBar
      {...rest}
      position="fixed"
      className={clsx(classes.root, className)}
    >
      <div style={{backgroundColor: colors.blue[900]}}>
        
        <Toolbar disableGutters className={classes.toolbarUs}>
        <div className={classes.logoContainer_beta}>
          <a href="/" title="Englewood Online High School">
            <Image
              className={classes.logoImage}
              src="/images/logos/beta.png"
              alt="Englewood Online High School"
              lazy={false}
            />
          </a>
        </div>
          <div className={classes.flexGrow} />
              <List className={classes.navigationContainer}>
                  <ListItem className={classes.listItem}>
                      <Typography
                          variant="body1"
                          color="inherit"
                          className={classes.listItemTextUs}
                          component="a"
                          target="blank"
                          href="https://accelerate-englewood.vschool.com"
                      >
                          Log in
                      </Typography>
                  </ListItem>
                  <ListItem className={classes.listItem}>
                      <Typography
                          variant="body1"
                          color="inherit"
                          className={classes.listItemTextUs}
                          component="a"
                          href="tel:201-408-2236"
                      >
                          (201) 408-2236
                      </Typography>
                  </ListItem>
              </List>
        </Toolbar>
      </div>
      <Toolbar disableGutters className={classes.toolbar}>
        <div className={classes.logoContainer}>
          <a href="/" title="Englewood Online High School">
            <Image
              className={classes.logoImage}
              src="/images/logos/0_logo.png"
              alt="Englewood Online High School"
              lazy={false}
            />
          </a>
        </div>
        <div className={classes.flexGrow} />
        <Hidden smDown>
          <List className={classes.navigationContainer}>
            {[howItWorks, admissionsPages, academics, studentLife, aboutUs].map((page, i) => (
              <div key={page.id}>
                <ListItem 
                  aria-describedby={page.id}
                  // onClick={e => handleClick(e, page.id)}
                  onClick={e => handleClickGotoPage(e, page.href)}
                  onMouseEnter={e => handleClick(e, page.id)}
                  className={clsx(
                    classes.listItem,
                    openedPopoverId === page.id ? classes.listItemActive : '',
                  )}
                >
                  <Typography
                    variant="body1"
                    color="textSecondary"
                    className={clsx(classes.listItemText, 'menu-item')}
                  >
                    {page.title}
                  </Typography>
                  <ListItemIcon className={classes.listItemIcon} >
                    <ExpandMoreIcon
                      className={
                        openedPopoverId === page.id ? classes.expandOpen : ''
                      }
                      fontSize="small"
                    />
                  </ListItemIcon>
                </ListItem>
                {/* <Popover
                  elevation={1}
                  id={page.id}
                  open={openedPopoverId === page.id}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                  classes={{ paper: classes.popover }}
                  PaperProps={{ onMouseLeave: handleClose }}
                >
                  <div>{renderPages(page.id)}</div>
                </Popover> */}
                <Popper onMouseLeave={handleClose}
                  id={page.id}
                  open={openedPopoverId === page.id}
                  anchorEl={anchorEl}
                  placement="top"
                  disablePortal={true}>
                  <Paper className={classes.popover}>
                    {renderPages(page.id)}
                  </Paper>
                </Popper>
              </div>
            ))}
          </List>
        </Hidden>
        <Hidden mdUp>
          <IconButton
            className={classes.iconButton}
            onClick={onSidebarOpen}
            aria-label="Menu"
          >
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
    <Toolbar className={classes.toolbarUs} />
    <Toolbar />
    </React.Fragment>
  );
};

Topbar.propTypes = {
  className: PropTypes.string,
  onSidebarOpen: PropTypes.func,
  pages: PropTypes.object.isRequired,
};

export default withRouter(Topbar);
