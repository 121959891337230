import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Section, SectionAlternate } from 'components/organisms';

import { Hero, 
        HowEnglewoodOHSWorks, 
        ThreeProgramsHero,
        AthleteAndPerformerProgramHero,
        AthleteAndPerformerProgram,
        StandardProgram,
        GiftedProgram } from './components';

import { howEnglewoodOHSWorks, athleteandperformer, standard, gifted, features, standardPrograms, giftedPrograms } from './data';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    width: '100%',
  },
  pagePaddingTop: {
    paddingTop: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(1),
    },
  },
  sectionNoPaddingTop: {
    paddingTop: 0,
  },
  pagePaddingTopAndBottom: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
  },
  footerNewsletterSection: {
    background: theme.palette.primary.main,
  },
}));

const HowItWorks = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Hero />
      
      <Section className={classes.pagePaddingTop}>
        <HowEnglewoodOHSWorks data={howEnglewoodOHSWorks} />
      </Section>
      <Section className={classes.pagePaddingTopAndBottom}>
        <ThreeProgramsHero />
      </Section>
      
      <Section className={classes.pagePaddingTopAndBottom}>
        <AthleteAndPerformerProgramHero />
      </Section>
      <Section className={classes.sectionNoPaddingTop}>
        <AthleteAndPerformerProgram data={athleteandperformer} />
      </Section>
      <Section className={classes.sectionNoPaddingTop}>
        <StandardProgram data={standard} />
      </Section>
      <Section className={classes.sectionNoPaddingTop}>
        <GiftedProgram data={gifted} />
      </Section>

      {/* <AboutMiddle />
      <Section>
        <Features data={features} />
      </Section>
      <AboutBottom />
      <SectionAlternate>
        <FeaturedArticles data={standardPrograms} />
      </SectionAlternate>
      <SectionAlternate className={classes.footerNewsletterSection}>
        <FooterNewsletter />
      </SectionAlternate>
      <SectionAlternate>
        <FeaturedArticles data={giftedPrograms} />
      </SectionAlternate> */}
    </div>
  );
};

export default HowItWorks;
