import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { ContactForm } from 'common';
import { SectionHeader } from 'components/molecules';
import { CardBase, HeroBackground } from 'components/organisms';

const useStyles = makeStyles(theme => ({
  root: {},
  title: {
    fontWeight: 500,
  },
  textWhite: {
    color: 'white',
  },
  subtitle: {
    fontWeight: 300,
  },
}));

const Contact = props => {
  const { className, ...rest } = props;
  const classes = useStyles();

  return (
    <div className={clsx(classes.root, className)} data-aos="fade-up" {...rest}>
      <HeroBackground
        backgroundImg="/images/admissions/contactus.jpg"
        backgroundColor="white"
        backgroundPosition="center center"
        disbaleCoverOpacity
      >
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <SectionHeader
              title="Would you like to join us?"
              subtitle="Englewood Online High School is committed to protecting your confidential information we acquired during the admissions and consultation process. We will not share your information with any third party outside of our organization unless you request to do so."
              align="left"
              disableGutter
              titleProps={{
                className: clsx(classes.title, classes.textWhite),
                variant: 'h4',
              }}
              subtitleProps={{
                className: clsx(classes.subtitle, classes.textWhite),
                variant: 'h6',
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CardBase withShadow liftUp>
              <ContactForm />
            </CardBase>
          </Grid>
        </Grid>
      </HeroBackground>
    </div>
  );
};

Contact.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
};

export default Contact;
