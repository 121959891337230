import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { colors } from '@material-ui/core';
import { Section, SectionAlternate } from 'components/organisms';
import {
  Hero,
  CurriculumOverview,
  Advantages,
  MiddleSchoolCurriculum,
  HighSchoolCurriculum,
  HighSchoolDiplomaRequirements,
  ExpandedEducation,
  LiveTeachingAndTutoring,
  HighSchoolResearchCourses,
  Features,
} from './components';

import {
    middleSchoolCurriculum,
    highSchoolCurriculum,
    highSchoolDiplomaRequirements,
    expandededucation,
  } from './data';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    width: '100%',
  },
  pagePaddingTop: {
    paddingTop: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(1),
    },
  },
  liveTeachingAndTutoringSection: {
    background: theme.palette.primary.main,
  },
}));

const Academics = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Hero />
      
      <Section className={classes.pagePaddingTop}>
        <MiddleSchoolCurriculum data={middleSchoolCurriculum} />
      </Section>

      <SectionAlternate>
        <HighSchoolCurriculum data={highSchoolCurriculum} />
      </SectionAlternate>
      <Section>
        <HighSchoolDiplomaRequirements data={highSchoolDiplomaRequirements} />
      </Section>

      <SectionAlternate>
        <ExpandedEducation data={expandededucation} />
      </SectionAlternate>

      <div className={classes.liveTeachingAndTutoringSection}>
        <Section>
          <LiveTeachingAndTutoring />
        </Section>
      </div>

      <SectionAlternate>
        <HighSchoolResearchCourses />
      </SectionAlternate>
    </div>
  );
};

export default Academics;
