import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useMediaQuery, Grid } from '@material-ui/core';
import { SectionHeader } from 'components/molecules';

const useStyles = makeStyles(theme => ({
  root: {},
}));

const WhoWeAre = props => {
  const { className, ...rest } = props;
  const classes = useStyles();

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Grid container spacing={isMd ? 4 : 2}>
        <Grid item xs={12} sm={6} data-aos="fade-up">
          <SectionHeader
            title="Our Staff"
            subtitle="Our administrators, academic advisors, and college counselors ensure that each and every student is thriving, both academically and personally."
            disableGutter
            align="left"
            subtitleProps={{
              variant: 'body1',
              // variant: 'h6',
              color: 'textPrimary',
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} data-aos="fade-up">
          <SectionHeader
            title="Our Student Body"
            subtitle="Our student body is motivated, diverse, and hails from all over the world. With shared clubs and activities, we allow our students to connect and form a global community dedicated to learning and curiosity."
            disableGutter
            align="left"
            subtitleProps={{
              variant: 'body1',
              // variant: 'h6',
              color: 'textPrimary',
            }}
          />
        </Grid>
      </Grid>
    </div>
  );
};

WhoWeAre.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
};

export default WhoWeAre;
