import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useMediaQuery } from '@material-ui/core';
import { SectionAlternate } from 'components/organisms';
import {
  Hero,
  MiddleSchoolPricings,
  HighSchoolPricings,
  Contact,
} from './components';

import {
  highSchoolPricings,
} from './data';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    width: '100%',
  },
  pagePaddingTop: {
    paddingTop: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(1),
    },
  },
  innerNarrowedClasses: {
    maxWidth: 700,
  },
  innerNarrowedClassesForHighSchool: {
    maxWidth: 1100,
  },
}));

const Admissions = () => {
  const classes = useStyles();

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <div className={classes.root}>
      <Hero />
      
      <SectionAlternate innerPadding={{ top: 8, bottom: 1 }} innerNarrowedClasses={classes.innerNarrowedClasses}>
        <MiddleSchoolPricings />
      </SectionAlternate>
      <SectionAlternate innerNarrowedClasses={classes.innerNarrowedClassesForHighSchool}>
        <HighSchoolPricings data={highSchoolPricings} />
      </SectionAlternate>

      <Contact />
    </div>
  );
};

export default Admissions;
