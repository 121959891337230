import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Section, SectionAlternate } from 'components/organisms';
import {
  Schedule3Programs,
  Hero,
  CollegeCounseling,
  ClubsAndExtracurricularActivities,
} from './components';

import {
  schedule3Programs,
  collegeCounseling,
  clubsAndExtracurricularActivities,
} from './data';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    width: '100%',
  },
  innerNarrowedClassesFor3Programs: {
    maxWidth: 1300,
  },
}));

const StudentLife = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Hero />
      <SectionAlternate innerPadding={{ top: 8 }} innerNarrowedClasses={classes.innerNarrowedClassesFor3Programs}>
        <Schedule3Programs data={schedule3Programs} />
      </SectionAlternate>
      <Section>
        <CollegeCounseling data={collegeCounseling} />
      </Section>
      <SectionAlternate>
        <ClubsAndExtracurricularActivities data={clubsAndExtracurricularActivities} />
      </SectionAlternate>
    </div>
  );
};

export default StudentLife;
