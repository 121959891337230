import React, { useEffect, useRef }  from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useMediaQuery, Grid } from '@material-ui/core';
import { SectionHeader } from 'components/molecules';
import { DescriptionListIcon, CardMediaBase } from 'components/organisms';
import { useLocation } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  root: {},
}));

const Features = props => {
  const { data, className, ...rest } = props;
  const classes = useStyles();

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const location = useLocation();
  const targetRef = useRef();
  const targetRefArr = {};


  useEffect(() => {

    if (targetRef && location.hash === '#core&electives') { 
      const bodyRect = document.body.getBoundingClientRect().top;
      const elementPosition = targetRef.current.getBoundingClientRect().top;
      const offsetPosition = elementPosition - bodyRect - 150;

      window.scrollTo({
          top: offsetPosition,
          behavior: "smooth"
      }); 

      // targetRef1.current.scrollIntoView({
      //   behavior: 'smooth'
      // });
    }

    if ((location.hash === '#testpreps' || location.hash === '#competitionentrybyclub' || location.hash === '#careereducation')) { 
      const bodyRect = document.body.getBoundingClientRect().top;
      const elementPosition = targetRefArr[location.hash.substring(1)].getBoundingClientRect().top;
      const offsetPosition = elementPosition - bodyRect - 150;

      window.scrollTo({
          top: offsetPosition,
          behavior: "smooth"
      }); 
    }
    
  }, [location.hash]);

  return (
    <div ref={targetRef} className={clsx(classes.root, className)} {...rest}>
      <SectionHeader
        title="Core & Electives"
        subtitle="We offer a wide selection of challenging core courses and electives for students to choose from, including several College Board approved AP courses, which allows our students to take college-level classes while still in high school. Students can select from a multitude of tantalizing electives not always offered at traditional brick and mortar schools: languages, technology and digital arts, health sciences, and business [from sterling academy, but we can change it], which will help students expand their educational horizons."
      />
      
      <Grid container spacing={isMd ? 4 : 2}>
        {data.map((item, index) => (
          <Grid item xs={12} md={4} key={index} data-aos="fade-up" ref={ref => (targetRefArr[item.key] = ref)} >
            <CardMediaBase liftUp noShadow variant="outlined" 
              cardMediaProps={item.image}
            >
              <DescriptionListIcon
                titleVariant='h5'
                title={item.title}
                subtitleVariant='h6'
                subtitle={item.subtitle}
                align="left"
              />
            </CardMediaBase>
          </Grid>
        ))}
      </Grid>
      
    </div>
  );
};

Features.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
  /**
   * data to be rendered
   */
  data: PropTypes.array.isRequired,
};

export default Features;
