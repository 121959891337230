import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Image } from 'components/atoms';
import { SectionHeader } from 'components/molecules';
import { Section } from 'components/organisms';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: '100%',
    position: 'relative',
    background: 'white',
    overflow: 'hidden',
  },
  image: {
    minHeight: 400,
    objectFit: 'cover',
    [theme.breakpoints.down('sm')]: {
      width: 'auto',
      minHeight: 550,
    },
  },
  title: {
    fontWeight: 500,
  },
  textWhite: {
    color: 'white',
  },
  subtitle: {
    fontWeight: 300,
  },
  section: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    paddingTop: 0,
    paddingBottom: 0,
  },
}));

const Hero = props => {
  const { className, ...rest } = props;
  const classes = useStyles();
  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Image
        src="/images/about-us/aboutus_01.png"
        srcSet="/images/about-us/aboutus_01.png"
        alt="About"
        className={classes.image}
        lazyProps={{
          width: '100%',
          height: '100%',
        }}
      />
      <Section className={classes.section}>
        <Grid item xs={12} md={9} data-aos={'fade-up'}>
          <SectionHeader
            title="About Us"
            subtitle="At Englewood Online High School, we prepare students to thrive in the global 21st century, whether preparing them for college or for stepping into the working world. Using our premier, asynchronous curriculum, students can receive a quality education while working on their own schedules and terms. Our certified teachers are dedicated to ensuring students receive quality instruction, and thus thrive in a rigorous academic environment. We look forward to meeting with you and helping you start your online school experience."
            align="left"
            data-aos="fade-up"
            disableGutter
            titleProps={{
              className: clsx(classes.title, classes.textWhite),
              variant: 'h2',
            }}
            subtitleProps={{
              className: clsx(classes.subtitle, classes.textWhite),
              variant: 'h5',
            }}
          />
        </Grid>
      </Section>
    </div>
  );
};

Hero.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
};

export default Hero;
