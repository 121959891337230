import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { useLocation } from 'react-router-dom';
import { SectionHeader } from 'components/molecules';
import { HeroBackground } from 'components/organisms';

const useStyles = makeStyles(theme => ({
  root: {},
  hero: {
    padding: theme.spacing(3, 2, 30, 2),
    [theme.breakpoints.up('md')]: {
      // padding: theme.spacing(5, 0, 30, 0),
      padding: theme.spacing(20),
    },
  },
  noPadding: {
    padding: 0,
  },
}));

const ThreeProgramsHero = props => {
  const { className, ...rest } = props;
  const classes = useStyles();

  const location = useLocation();
  const targetRef = useRef();

  useEffect(() => {
    if (targetRef && location.hash === '#three-programs') { 
      const bodyRect = document.body.getBoundingClientRect().top;
      const elementPosition = targetRef.current.getBoundingClientRect().top;
      const offsetPosition = elementPosition - bodyRect - 150;

      window.scrollTo({
          top: offsetPosition,
          behavior: "smooth"
      }); 

      // targetRef.current.scrollIntoView({
      //   behavior: 'smooth'
      // });
    }
  }, [location.hash]);


  return (
    <div ref={targetRef} className={clsx(classes.root, className)} data-aos="fade-up" {...rest}>
      <SectionHeader
          title="Our Three Programs"
          subtitle="Englewood Online High School offers three different tracks for students with different academic goals."
          fadeUp
          titleVariant="h3"
        />
      <HeroBackground
        backgroundImg="/images/how-it-works/treeprograms.jpg"
        backgroundColor="white"
        backgroundPosition="center center"
        contentSectionClassName={classes.noPadding}
        className={classes.hero}
        disbaleCoverOpacity
      >
        
      </HeroBackground>
    </div>
  );
};

ThreeProgramsHero.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
};

export default ThreeProgramsHero;
