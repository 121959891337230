import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid, Button, TextField, Snackbar } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import validate from 'validate.js';
import emailjs from 'emailjs-com';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
}));

const schema = {
  name: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 128,
    },
  },
  email: {
    presence: { allowEmpty: false, message: 'is required' },
    email: true,
    length: {
      maximum: 300,
    },
  },
  message: {
    presence: { allowEmpty: false, message: 'is required' },
  },
};

const ContactForm = (props) => {
  const { drawerHandle: drawerHandle, ...rest } = props;

  const classes = useStyles();

  const [formState, setFormState] = React.useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  });
  const [emailSnackbarOpen, setEmailSnackbarOpen] = React.useState(false);

  React.useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }));
  }, [formState.values]);

  const handleChange = event => {
    event.persist();

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };

  const hasError = field =>
    formState.touched[field] && formState.errors[field] ? true : false;

  
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_0aldcat', 'template_2nggt08', e.target, 'user_B1Dx6UMDlIhdrIxwSHhnH')
      .then((result) => {
          console.log(result.text);
          if (result.text === "OK") {
            setEmailSnackbarOpen(true);

            // if draw email form then close draw
            if (drawerHandle) {
              const timer = setTimeout(() => {
                drawerHandle();
              }, 2200);
              return () => clearTimeout(timer);
            }
            // clear form
            setFormState({isValid:false, values:{}, touched:{}, errors:{}});
          }
      }, (error) => {
          console.log("##### " + error.text);
      });

      // action="/submition.html?contact-form-submit-success=true"
  }


  const getFormData = (form) => {
    var elements = form.elements;
    var honeypot;

    var fields = Object.keys(elements).filter(function(k) {
      if (elements[k].name === "honeypot") {
        honeypot = elements[k].value;
        return false;
      }
      return true;
    }).map(function(k) {
      if(elements[k].name !== undefined) {
        return elements[k].name;
      // special case for Edge's html collection
      }else if(elements[k].length > 0){
        return elements[k].item(0).name;
      }
    }).filter(function(item, pos, self) {
      return self.indexOf(item) == pos && item;
    });

    var formData = {};
    fields.forEach(function(name){
      var element = elements[name];
      
      // singular form elements just have one value
      formData[name] = element.value;

      // when our element has multiple items, get their values
      if (element.length) {
        var data = [];
        for (var i = 0; i < element.length; i++) {
          var item = element.item(i);
          if (item.checked || item.selected) {
            data.push(item.value);
          }
        }
        formData[name] = data.join(', ');
      }
    });

    // add form-specific values into the data
    formData.formDataNameOrder = JSON.stringify(fields);
    formData.formGoogleSheetName = form.dataset.sheet || "responses"; // default sheet name
    formData.formGoogleSendEmail
      = form.dataset.email || ""; // no email by default

    return {data: formData, honeypot: honeypot};
  }

  const sendEmail2 = (event) => {
    event.preventDefault();           // we are submitting via xhr below
    var form = event.target;
    var formData = getFormData(form);
    var data = formData.data;

    // If a honeypot field is filled, assume it was done so by a spam bot.
    if (formData.honeypot) {
      return false;
    }

    // var url = form.action;
    var url = "https://script.google.com/macros/s/AKfycbywrN3SCaRPJbYREdSu0l9pxyja9gk-2GeQtlgUSf-coMFcCMAO3DAXt74DldkQT9tp/exec"
    var xhr = new XMLHttpRequest();
    xhr.open('POST', url);
    // xhr.withCredentials = true;
    xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
    xhr.onreadystatechange = function() {
        if (xhr.readyState === 4 && xhr.status === 200) {

          setEmailSnackbarOpen(true);

          // if draw email form then close draw
          if (drawerHandle) {
            const timer = setTimeout(() => {
              drawerHandle();
            }, 2200);
            return () => clearTimeout(timer);
          }
          // clear form
          setFormState({isValid:false, values:{}, touched:{}, errors:{}});
        }
    };
    // url encode form data for sending as post data
    var encoded = Object.keys(data).map(function(k) {
        return encodeURIComponent(k) + "=" + encodeURIComponent(data[k]);
    }).join('&');
    xhr.send(encoded);
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setEmailSnackbarOpen(false);
  };

  return (
    <div className={classes.root}>
      <Snackbar open={emailSnackbarOpen} autoHideDuration={2000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success">
          <AlertTitle>Success</AlertTitle>
            Sent a message!
        </Alert>
      </Snackbar>
      <form
        id = "gform"
        name = "gform"
        // name="contact-form"
        method="post"
        onSubmit={sendEmail2}
      >
        {/* <input type="hidden" name="form-name" value="contact-form" /> */}
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h4" align="center">
              <strong>Contact Us</strong>
            </Typography>
            <Typography variant="h6" color="textSecondary" align="center">
              We carefully read and answer to all our emails.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              placeholder="Your Full Name"
              label="Full Name *"
              variant="outlined"
              size="medium"
              name="name"
              // id="name"
              // name="name"
              fullWidth
              helperText={
                hasError('name') ? formState.errors.name[0] : null
              }
              error={hasError('name')}
              onChange={handleChange}
              type="text"
              value={formState.values.name || ''}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              placeholder="Your E-mail"
              label="E-mail *"
              variant="outlined"
              size="medium"
              id="email"
              name="email"
              fullWidth
              helperText={hasError('email') ? formState.errors.email[0] : null}
              error={hasError('email')}
              onChange={handleChange}
              type="email"
              value={formState.values.email || ''}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              placeholder="Message"
              label="Message *"
              variant="outlined"
              id="message"
              name="message"
              fullWidth
              helperText={
                hasError('message') ? formState.errors.message[0] : null
              }
              error={hasError('message')}
              onChange={handleChange}
              multiline
              rows={4}
              value={formState.values.message || ''}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle2" gutterBottom>
              Fields that are marked with * sign are required.
            </Typography>
            <Button
              size="large"
              variant="contained"
              type="submit"
              color="primary"
              disabled={!formState.isValid}
            >
              Send
            </Button>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default ContactForm;
