import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Section, SectionAlternate } from 'components/organisms';
import {
  Hero,
  OurTeachers,
  WhoWeAre,
  Partners,
  Team,
  Contact,
  Gallery,
  
} from './components';

import { team, universities, mapData, gallery } from './data';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    width: '100%',
  },
  sectionPaddingTop: {
    paddingTop: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(1),
    },
  },
  sectionPaddingBottom: {
    paddingBottom: theme.spacing(0),
    [theme.breakpoints.up('md')]: {
      paddingBottom: theme.spacing(2),
    },
  },
  sectionPartners: {
    boxShadow: '0 5px 20px 0 rgba(90, 202, 157, 0.05)',
    backgroundColor: theme.palette.alternate,
    '& .section-alternate__content': {
      paddingTop: theme.spacing(5),
      paddingBottom: theme.spacing(5),
    },
  },
}));

const About = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Hero />

      <Section className={classes.sectionPaddingTop, classes.sectionPaddingBottom}>
        <OurTeachers />
      </Section>

      <Section className={classes.sectionPaddingTop}>
        <WhoWeAre />
      </Section>

      {/* <Section className={classes.sectionPaddingTop}>
        <Team data={team} />
      </Section> */}

      {/* <SectionAlternate className={classes.sectionPartners}>
        <Partners data={universities} />
      </SectionAlternate> */}

      <Contact data={mapData} />
      
      {/* <SectionAlternate>
        <Gallery data={gallery} />
      </SectionAlternate> */}
    </div>
  );
};

export default About;
