import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  useMediaQuery,
  Grid,
  Typography,
  colors, 
} from '@material-ui/core';
import { Image, IconText } from 'components/atoms';
import { SectionHeader } from 'components/molecules';
import { CardProduct } from 'components/organisms';

const useStyles = makeStyles((theme) => ({
  root: {},
  fontWeight400: {
    fontWeight: 400,
    width: "100%"
  },
  coverImage: {
    objectFit: 'cover',
  },
  cardProduct: {
    '& .card-product__content': {
      padding: theme.spacing(3),
      paddingLeft: theme.spacing(5),
    },
    '& .card-product__media': {
      height: 245,
    },
  },
  gridItem: {
    border: 0,

    '&:nth-child(-n+7)': {
      borderBottom: `1px solid ${colors.blueGrey[100]}`,
    },
    height: 70,

    [theme.breakpoints.up('md')]: {
      '&:nth-child': {
        borderBottom: 0,
      },
      '&:nth-child': {
        borderRight: 0,
      },
      '&:nth-child(-n+7)': {
        borderBottom: `1px solid ${colors.blueGrey[100]}`,
      },
      height: 90,
    },
  },
}));

const Schedule3Programs = props => {
  const { data, className, ...rest } = props;
  const classes = useStyles();

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });
  const betweenSmMd = useMediaQuery(theme.breakpoints.between('960', 'md'), {
    defaultMatches: true,
  });
  

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <SectionHeader data-aos={'fade-up'}
        title="3 Programs"
        subtitle=""
        titleVariant="h3"
      />
      <Grid container spacing={isMd ? 4 : 7}>
        {data.map((item, index) => (
          <Grid key={index} item xs={12} md={4} data-aos={'fade-up'}>
            { betweenSmMd && 
              <Typography
                variant="h6"
                style={{ marginBottom: 0 }}
              >
                {item.title1}
              </Typography>
            }
            { betweenSmMd && 
              <Typography
                variant="h6"
                style={{ marginTop: 0, marginBottom: 10 }}
              >
                {item.title2}
              </Typography>
            }
            { !betweenSmMd && 
              <Typography
                variant="h6"
                style={{ marginBottom: 10 }}
              >
                {item.title}
              </Typography>
            }

            <CardProduct
              className={classes.cardProduct}
              withShadow
              liftUp
              mediaContent={
                <Image
                  className={classes.coverImage}
                  {...item.cover}
                  alt={item.title}
                  lazyProps={{
                    width: '100%',
                    height: '100%',
                  }}
                />
              }
              cardContent={
                item.schedules.map((schedule, t) => (
                  <Grid container spacing={0} key={t} className={classes.gridItem}>
                    <Grid 
                      item
                      container
                      alignItems="center"
                      wrap="nowrap"
                      xs={12}
                    >
                      {schedule.apm === "AM" &&
                        <IconText style={{width: "60%"}}
                          iconProps={{size:"extraSmall"}}
                          fontIconClass="far fa-clock"
                          color={colors.indigo[500]}
                          title={schedule.time}
                        />
                      }
                      {schedule.apm === "PM" &&
                        <IconText style={{width: "60%"}}
                          iconProps={{size:"extraSmall"}}
                          fontIconClass="fas fa-clock"
                          color={colors.indigo[500]}
                          title={schedule.time}
                        />
                      }

                      <Typography
                        variant="body1"
                        color="textPrimary"
                        align="left"
                        className={classes.fontWeight400}
                      >
                        {schedule.title}
                      </Typography>
                    </Grid>
                  </Grid>
                ))
              }
            />
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

Schedule3Programs.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
  /**
   * data to be rendered
   */
  data: PropTypes.array.isRequired,
};

export default Schedule3Programs;
