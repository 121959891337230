import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  useMediaQuery,
  Typography,
  Grid,
  ListItem,
  ListItemAvatar,
  Avatar,
  Divider,
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { SectionHeader } from 'components/molecules';
import { Image } from 'components/atoms';
import { CardProduct } from 'components/organisms';

const useStyles = makeStyles(theme => ({
  root: {},
  cardProduct: {
    display: 'flex',
    height: '100%',
    borderRadius: theme.spacing(1),
    '& .card-product__content, & .card-product__media': {
      flex: '1 1 50%',
      height: '100%',
    },
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column !important',
      '& .card-product__content, & .card-product__media': {
        flex: '1 1 100%',
      },
    },
  },
  cardProductReverse: {
    flexDirection: 'row-reverse',
    '& .card-product__media img': {
      borderRadius: theme.spacing(0, 0, 0, 20),
    },
  },
  image: {
    objectFit: 'cover',
    borderRadius: theme.spacing(0, 0, 20, 0),
  },
  blogContent: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  list: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  avatarContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  avatar: {
    marginRight: theme.spacing(0),
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
  expandedPanel: {
    marginBottom: 0,
  }
}));

const Big5Reasons = props => {
  const { data, className, ...rest } = props;
  const classes = useStyles();

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });
  const isSm = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  });

  const BlogMediaContent = props => (
    <Image
      {...props}
      className={classes.image}
      lazyProps={{ width: '100%', height: '100%' }}
    />
  );

  const BlogContent = props => (
    <div className={classes.blogContent}>
      <Typography variant="h5" color="textPrimary" gutterBottom>
        {props.title}
      </Typography>
      { isMd && <br />}
      
      <div>
        {props.tags.map((item, index) => (
          <Grid item xs={12} key={index} data-aos="fade-up">
            <ListItem disableGutters dense>
              <ListItemAvatar className={classes.listItemAvatar}>
                <Avatar
                  src="/images/icons/2_icon_provides.png"
                />
              </ListItemAvatar>
              <Typography variant="subtitle1" color="textPrimary">
                {item}
              </Typography>
            </ListItem>
          </Grid>
        ))}
      </div>
      
      <Divider className={classes.divider} />
      
      <div>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            classes={{ expanded:classes.expandedPanel }}
          >
            <div>
              <Typography variant="body1">{props.subtitle}</Typography>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <div>
              <Typography variant="body1">
                {props.subtitle2}
              </Typography>
            </div>
          </AccordionDetails>
        </Accordion>
      </div>
    </div>
  );

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <SectionHeader disableGutter
        label="" 
        title="Big 5 Reasons to Choose Englewood Online High School" 
        titleVariant="h4"
      />
      <br />
      <br />
      <Grid container spacing={isMd ? 4 : 2}>
        {data.map((item, index) => (
          <Grid item xs={12} key={index} data-aos="fade-up">
            <CardProduct
              withShadow
              liftUp
              className={clsx(
                isSm ? classes.cardProduct : '',
                index % 2 !== 0 ? classes.cardProductReverse : {},
              )}
              mediaContent={
                <BlogMediaContent {...item.cover} alt={item.title} />
              }
              cardContent={
                <BlogContent
                  title={item.title}
                  subtitle={item.subtitle}
                  subtitle2={item.subtitle2}
                  tags={item.tags}
                />
              }
            />
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

Big5Reasons.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
  /**
   * data to be rendered
   */
  data: PropTypes.array.isRequired,
};

export default Big5Reasons;
