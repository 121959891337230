import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Image } from 'components/atoms';
import { SectionHeader } from 'components/molecules';
import { Section } from 'components/organisms';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: '100%',
    position: 'relative',
    background: 'white',
    overflow: 'hidden',
  },
  image: {
    minHeight: 400,
    objectFit: 'cover',
    [theme.breakpoints.down('sm')]: {
      width: 'auto',
      minHeight: 500,
    },
  },
  title: {
    fontWeight: 500,
  },
  textWhite: {
    color: 'white',
  },
  subtitle: {
    fontWeight: 300,
  },
  section: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    paddingTop: 0,
    paddingBottom: 0,
  },
}));

const Hero = props => {
  const { className, ...rest } = props;
  const classes = useStyles();
  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Image
        src="/images/academics/topbanner.png"
        srcSet="/images/academics/topbanner.png"
        alt="Curriculum overview"
        className={classes.image}
        lazyProps={{
          width: '100%',
          height: '100%',
        }}
      />
      <Section className={classes.section}>
        <Grid item xs={12} md={9} data-aos={'fade-up'}>
          <SectionHeader
            title="Curriculum Overview"
            subtitle={
              <span>
                Englewood Academy provides students with a comprehensive, dynamic curriculum experience. 
                Offering an extensive selection of both core courses and electives, students are encouraged to think 
                critically and develop an independent work ethic that will prepare them for college and beyond.
              </span>
            }
            align="left"
            data-aos="fade-up"
            disableGutter
            titleProps={{
              className: clsx(classes.title, classes.textWhite),
              variant: 'h2',
            }}
            subtitleProps={{
              className: clsx(classes.subtitle, classes.textWhite),
              variant: 'h5',
            }}
          />
        </Grid>
      </Section>
    </div>
  );
};

Hero.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
};

export default Hero;
