import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { colors } from '@material-ui/core';
import { Section, SectionAlternate } from 'components/organisms';
import {
  Hero,
  Big5Reasons,
  Gallery,
  AdmissionsOffice,
  WhoWeAre,
  Contact,
} from './components';

import {
  big5reasons,
  gallery,
} from './data';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    width: '100%',
  },
  pagePaddingTop: {
    paddingTop: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(4),
    },
  },
  sectionAlternate: {
    background: theme.palette.alternate
  },
  gallerySection: {
    // background: '#7E7D79',
    background: 'transparent',
    backgroundImage: `linear-gradient(90deg, #424242 50%, #323232 50%)`,
  },
  reviewSection: {
    background: theme.palette.primary.main,
  },
}));

const Landing = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Hero />

      <SectionAlternate innerPadding={{top:80, bottom: 80}}>
        <Big5Reasons data={big5reasons} />
      </SectionAlternate>
      
      <Section>
        <Gallery data={gallery} />
      </Section>

      <AdmissionsOffice className={classes.sectionAlternate} />

      <Section>
        <WhoWeAre />
      </Section>
     
      <Contact />
    </div>
  );
};

export default Landing;
