import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, ListItem, ListItemAvatar, ListItemText, Avatar, } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {},
  title: {
    fontWeight: 500,
  },
  avatar: {
    background: 'transparent',
    width: 50,
    height: 50,
    borderRadius: theme.spacing(1),
  },
  listItemAvatar: {
    marginRight: theme.spacing(2),
  },
}));

/**
 * Component to display the description list with icon
 *
 * @param {Object} props
 */
const DescriptionListIconWithHTitle = props => {
  const {
    iconsrc,
    title,
    subtitle,
    icon,
    align,
    titleVariant,
    subtitleVariant,
    className,
    titleProps,
    subtitleProps,
    ...rest
  } = props;

  const classes = useStyles();

  let gridJustify = 'center';

  if (align === 'left') {
    gridJustify = 'flex-start';
  } else if (align === 'right') {
    gridJustify = 'flex-end';
  }

  return (
    <Grid
      container
      spacing={2}
      {...rest}
      className={clsx('description-list-icon', classes.root, className)}
    >
      <Grid 
        container
        justify={gridJustify}
        item
        xs={12}>
        <ListItem disableGutters>
          <ListItemAvatar className={classes.listItemAvatar}>
            <Avatar src={iconsrc} className={classes.avatar} />
          </ListItemAvatar>
          <ListItemText
            primary={title}
            primaryTypographyProps={titleProps}
          />
        </ListItem>
      </Grid>
      {subtitle && (
        <Grid item xs={12} className="description-list-icon__subtitle-wrapper">
          <Typography
            variant={subtitleVariant}
            color="textSecondary"
            align={align}
            className="description-list-icon__subtitle"
          >
            {subtitle}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};

DescriptionListIconWithHTitle.defaultProps = {
  align: 'center',
  titleVariant: 'h6',
  subtitleVariant: 'body1',
  titleProps: {variant: 'h6'},
  subtitleProps: {},
};

DescriptionListIconWithHTitle.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,

  iconsrc: PropTypes.string,

  /**
   * The title
   */
  title: PropTypes.string.isRequired,
  /**
   * the subtitle
   */
  subtitle: PropTypes.string,
  /**
   * Whether should show the alternate icon
   */
  icon: PropTypes.node,
  /**
   * The alignment of the items
   */
  align: PropTypes.oneOf(['left', 'right', 'center']),
  /**
   * Title variant
   */
  titleVariant: PropTypes.string,
  /**
   * Subtitle variant
   */
  subtitleVariant: PropTypes.string,
  /**
   * Additional props to pass to the title Typography component
   */
  titleProps: PropTypes.object,
  /**
   * Additional props to pass to the subtitle Typography component
   */
  subtitleProps: PropTypes.object,
};

export default DescriptionListIconWithHTitle;
