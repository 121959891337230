import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import {
  Typography,
  List,
  ListItem,
  ListItemText,
} from '@material-ui/core';
import { Image } from 'components/atoms';
import { SectionHeader } from 'components/molecules';
import { Section, CardBase } from 'components/organisms';

const useStyles = makeStyles(theme => ({
  root: {
    // background: theme.palette.white,
    backgroundSize: 'cover',
    padding: theme.spacing(6, 0),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(12, 0),
    },
    width: '100%',
    height: '100%',
    overflow: 'hidden',
  },
  hero: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    minHeight: 470,
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
    },
  },
  section: {
    paddingTop: 0,
  },
  sectionHeader: {
    padding: theme.spacing(0, 2),
    marginBottom: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      maxWidth: '50%',
      width: 'calc(100vw - 625px)',
      marginBottom: 0,
    },
  },
  textWhite: {
    color: 'white',
  },
  image: {
    alignSelf: 'center',
    maxWidth: 625,
    height: 'auto',
    [theme.breakpoints.up('sm')]: {
      top: '100%',
      width: 'auto',
    },

    [theme.breakpoints.up('md')]: {
      position: 'absolute',
      top: '50%',
      right: 20,
      width: 'auto',
      transform: 'translateY(-50%) !important',
    },

    [theme.breakpoints.up('lg')]: {
      position: 'absolute',
      top: '50%',
      right: 60,
      width: 'auto',
      transform: 'translateY(-50%) !important',
    },

    [theme.breakpoints.between(1600, 'xl')]: {
      position: 'absolute',
      top: '50%',
      right: 200,
      width: 'auto',
      transform: 'translateY(-50%) !important',
    },

    [theme.breakpoints.up('xl')]: {
      position: 'absolute',
      top: '50%',
      right: 360,
      width: 'auto',
      transform: 'translateY(-50%) !important',
    },
  },
  card: {
    borderBottom: `4px solid ${theme.palette.secondary.main}`,
    '& .MuiCardContent-root': {
      padding: 30,
    },
  },
  cardTitle: {
    fontWeight: 500,
    marginBottom: theme.spacing(2),
  },
}));

const AdmissionsOffice = props => {
  const { className, ...rest } = props;
  const classes = useStyles();

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <div className={classes.hero}>
        <Section className={classes.section} disablePadding>
          <div className={classes.sectionHeader}>
            {/* <Typography variant="h6" gutterBottom color="secondary">
              ENGLEWOOD ACADEMY
            </Typography> */}
            <SectionHeader
              title="Admissions"
              subtitle="We accept applications on a rolling basis, so students can start their courses whenever it is most convenient. We also encourage prospective students and parents to attend one of our webinars, course demos, or get in touch with a member of our admissions team."
              subtitleColor="textPrimary"
              align="left"
              data-aos="fade-up"
            />
            <CardBase withShadow liftUp align="left" className={classes.card} data-aos="fade-up">
              <Typography className={classes.cardTitle} variant="h6">
                To set up a consultation or a class demo, please call us at <span style={{ color: "#3F51B5" }}>(201) 408-2236</span>, 
                or set up an appointment with an admissions specialist by emailing {' '}
                <span style={{ textDecoration: 'underline', color: "#3F51B5" }}>admissions@englewoodohs.org</span>
              </Typography>
              <List disablePadding>
                <ListItem disableGutters>
                  <ListItemText
                    primary="Admissions Office"
                    secondary="Englewood Online High School"
                    secondaryTypographyProps={{ color:"secondary" }}
                  />
                </ListItem>
              </List>
            </CardBase>
          </div>
        </Section>
        <Image
          src="/images/landing/4_macbook-dashboard_new.png"
          className={classes.image}
          data-aos="fade-up"
          lazy={false}
        />
      </div>
    </div>
  );
};

AdmissionsOffice.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
};

export default AdmissionsOffice;
