import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import {
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
} from '@material-ui/core';
import { SectionHeader } from 'components/molecules';
import { HeroShaped, Map } from 'components/organisms';

const useStyles = makeStyles((theme) => ({
  root: {},
  map: {
    zIndex: 9,
  },
  icon: {
    background: 'transparent',
    borderRadius: 0,
  },
  rightSidePadding: {
    padding: theme.spacing(15,20,15,20),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(5,5,5,5),
    },
  },
  rightSideCoverwidth: {
    width: '30vw',
    [theme.breakpoints.down('sm')]: {
      width: '90%',
    },
  },
}));

const Contact = props => {
  const { data, className, ...rest } = props;
  const classes = useStyles();

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <HeroShaped
        leftSide={
          <div>
            <SectionHeader
              title="Contact Us"
              subtitle="Please call or email us to learn more about our services or to enroll in our 5-Day Express or Full-Service Application Packages!"
              subtitleProps={{
                variant: 'body1',
                color: 'textPrimary',
              }}
              data-aos="fade-up"
              align="left"
            />
            <List disablePadding>
              <ListItem disableGutters data-aos="fade-up">
                <ListItemAvatar className={classes.listItemAvatar}>
                  <Avatar
                    src="/images/about-us/contact-icon-phone.png"
                    srcSet="/images/about-us/contact-icon-phone@2x.png 2x"
                    className={classes.icon}
                  />
                </ListItemAvatar>
                <ListItemText
                  className={classes.listItemText}
                  primary="Phone"
                  secondary="+1 (201) 408-2236"
                  primaryTypographyProps={{
                    className: classes.title,
                    variant: 'subtitle1',
                    color: 'textSecondary',
                  }}
                  secondaryTypographyProps={{
                    variant: 'subtitle1',
                    color: 'textPrimary',
                  }}
                />
              </ListItem>
              <ListItem disableGutters data-aos="fade-up">
                <ListItemAvatar className={classes.listItemAvatar}>
                  <Avatar
                    src="/images/about-us/contact-icon-mail.png"
                    srcSet="/images/about-us/contact-icon-mail@2x.png 2x"
                    className={classes.icon}
                  />
                </ListItemAvatar>
                <ListItemText
                  className={classes.listItemText}
                  primary="Email"
                  secondary="contact@englewoodohs.org"
                  primaryTypographyProps={{
                    className: classes.title,
                    variant: 'subtitle1',
                    color: 'textSecondary',
                  }}
                  secondaryTypographyProps={{
                    variant: 'subtitle1',
                    color: 'textPrimary',
                  }}
                />
              </ListItem>
              <ListItem disableGutters data-aos="fade-up">
                <ListItemAvatar className={classes.listItemAvatar}>
                  <Avatar
                    src="/images/about-us/contact-icon-pin.png"
                    srcSet="/images/about-us/contact-icon-pin@2x.png 2x"
                    className={classes.icon}
                  />
                </ListItemAvatar>
                <ListItemText
                  className={classes.listItemText}
                  primary="Head Office"
                  secondary="270 Sylvan Avenue Englewood Cliffs, NJ 07632"
                  primaryTypographyProps={{
                    className: classes.title,
                    variant: 'subtitle1',
                    color: 'textSecondary',
                  }}
                  secondaryTypographyProps={{
                    variant: 'subtitle1',
                    color: 'textPrimary',
                  }}
                />
              </ListItem>
            </List>
          </div>
        }
        rightSide={
          <Map
            zoom = {13}
            center={[40.8696625, -73.9582234]}
            pins={data}
            className={classes.map}
          />
        }
        rightSidePadding={clsx(classes.rightSidePadding)}
        rightSideCoverwidth={clsx(classes.rightSideCoverwidth)}
      />
    </div>
  );
};

Contact.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
  /**
   * data to be rendered
   */
  data: PropTypes.array.isRequired,
};

export default Contact;
