import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    background: theme.palette.alternate,
  },
  inner: {
    maxWidth: 1280,
    width: '100%',
    margin: '0 auto',
    padding: theme.spacing(6, 2),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(12, 2),
    },
  },
  innerNarrowed: {
    maxWidth: 880,
  },
  
}));

/**
 * Component to display the alternative sections
 *
 * @param {Object} props
 */
const SectionAlternate = props => {
  const { children, innerPadding, innerNarrowed, innerNarrowedClasses, className, ...rest } = props;

  const classes = useStyles();

  const useCustomStyles = makeStyles((theme) => ({
    innerPadding: {
      paddingTop: innerPadding && innerPadding.top ? innerPadding.top : 96,
      paddingBottom: innerPadding && innerPadding.bottom ? innerPadding.bottom : 96,
    },
    
  }));

  const customClasses = useCustomStyles();

  return (
    <section
      className={clsx('section-alternate', classes.root, className)}
      {...rest}
    >
      <div
        className={clsx(
          'section-alternate__content',
          classes.inner,
          innerPadding ? customClasses.innerPadding : {},
          innerNarrowed ? classes.innerNarrowed : {},
          innerNarrowedClasses ? innerNarrowedClasses : {},
        )}
      >
        {children}
      </div>
    </section>
  );
};

SectionAlternate.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
  /**
   * Children to placed inside the section
   */
  children: PropTypes.node,
  /**
   * Should show narrow sections
   */
  innerNarrowed: PropTypes.bool,
  /**
   * Inner padding
   */
  innerPadding: PropTypes.object,
};

export default SectionAlternate;
