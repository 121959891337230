export const howEnglewoodOHSWorks = [
  {
    title: 'Choose Your Program',
    description:
      `Because no student is the same, Englewood Online High School offers three different programs to choose from.
        Besides our standard middle and high school program,
        we also offer our student athletes and performers program and gifted student program.` ,
    icon: '/images/icons/chooseprogram.png',
    learnMore: 'true',
    learnMoreLink: '/student-life'
  },
  {
    title: 'Tailor Your Curriculum',
    description:
        `Our advisors will walk you through all available curriculums and hand pick the best  courses for your child.
         We consider each individual’s academic goals and preferences so all students can live their full academic potential.
         Special courses like guided research on customized topics will be available for qualified students.`,
    icon: '/images/icons/tailorcurriculum.png',
    learnMore: 'true',
    learnMoreLink: '/academics#high-school-curriculum'
  },
  {
    title: 'Start Your Learning from Anywhere, at Any Time',
    description:
      `Due to the benefit of our asynchronous virtual curriculum, students can start their learning from anywhere,
       at any time. As long as students understand the material and complete assignments and exams,
        they will be able to learn at their own pace, best suited to their schedule.`,
    icon: '/images/icons/anywheretime.png',
    learnMore: 'false'
  },
  {
    title: 'Personal Student Support',
    description:
      `Online education is happening individually, but Englewood Online High School students are never alone.
       Our excellent teachers host office hours and personalized one-on-one learning sessions.
        Additionally, our advisors  keep in touch with students regularly to support their academic needs.`,
    icon: '/images/icons/personalsupport.png',
    learnMore: 'false'
  },
];


export const athleteandperformer = [
  {
    icon: '/images/icons/2_icon_management.png',
    title: 'Regimented Program',
    subtitle:
      'Our Student athlete and performer program fulfills all the requirements to earn their high school diploma and to be recruited by NCAA eligible D1 college sports teams.',
  },
  {
    icon: '/images/icons/2_icon_service.png',
    title: 'Flexible Schedule',
    subtitle:
      'All the core courses consist of interactive and engaging lessons that students can move through at their own pace.',
  },
  {
    icon: '/images/icons/2_icon_education.png',
    title: 'Personal Support',
    subtitle:
      `Students will have the opportunity to receive additional support via email with teachers, as well as regular conferences with their academic advisor to track the student’s progress and modify their personalized learning plan.`,
  },
];



export const standard = [
  {
    icon: '/images/icons/standard_morecourses.png',
    title: 'More Courses',
    subtitle:
      `Standard program offers more diverse areas of studies than athletic and performer program. Full spectrum of middle and high school curriculum is available in the same convenient asynchronous, self-paced format.`,
  },
  {
    icon: '/images/icons/standard_moresupport.png',
    title: 'More Support',
    subtitle:
      `To ensure each student is progressing well in their studies, our dedicated teachers  hold office hours on top of frequent email communication. Additionally, students are provided with a personal academic counselor, to ensure students are well supported, as well as optimize the student’s academic plan toward college admissions.`,
  },
  {
    icon: '/images/icons/standard_research.png',
    title: 'Research and Competitions',
    subtitle:
      `We encourage students in our standard program to pursue academic research or challenge themselves with academic competitions. Introductory lessons for essay contests, mathematics and science competitions, and research opportunities are available at no additional cost.`,
  },
];


export const gifted = [
  {
    icon: '/images/icons/gifted_advancedcurriculum.png',
    title: 'Advanced Curriculum',
    subtitle:
      `We offer various AP courses to meet our gifted and talented students’ academic needs. Since our program is mostly self paced, we encourage gifted students to complete more courses by accelerating their learning and utilizing their summer vacation.`,
  },
  {
    icon: '/images/icons/gifted_mostsupport.png',
    title: 'The Most Support',
    subtitle:
      `To ensure each student is progressing well in their studies, our dedicated teachers are there to meet in one-on-one meetings to ensure that the student is understanding the material and not falling behind.`,
  },
  {
    icon: '/images/icons/gifted_counselor.png',
    title: 'Counselor',
    subtitle:
      `Additionally, students are provided with a personal academic counselor to ensure students are well supported, as well as optimize the student’s academic plan. Our credentialed counselors guide students all the way through the college admission process.`,
  },
  {
    icon: '/images/icons/gifted_research.png',
    title: 'Research and Competitions',
    subtitle:
      `For our gifted students, we greatly encourage each and every one to pursue academic research in high school, and we help connect interested students with dedicated mentors who will guide them through the research process. We also provide help with publication support, so students can see their work published in academic journals and conferences. We provide several courses and clubs for gifted students that focus on research activities. Through challenging academic competitions, students can reach the next level of their academic achievements and develop their potential. Popular competitions include the Scholastic Art and Writing Contest, Concord Review Journal, AMC, and Olympiads.`,
  },
];



export const features = [
    'Lifetime Updates & Introduction and Working',
    'Tech support & Mutual Funds',
    'Tons of assets & Lifetime Updates',
    'Integration ready & Tech support',
  ];


  export const standardPrograms = [
    {
      cover: {
        src: '/images/photos/ourprograms/ourprograms_11.png',
        srcSet: '/images/photos/ourprograms/ourprograms_11.png',
      },
      title: 'Support‎',
      subtitle:
        'To ensure each student is progressing well in their studies, our dedicated teachers are there to meet one-on-one in weekly meetings to ensure that the student is understanding the material and not falling behind. Additionally, students are provided with a personal academic counselor, ensuring that students attend with bi-weekly meetings with our credentialed counselors to ensure students are well supported, as well as modify the student’s academic plan.  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    },
    {
      cover: {
        src: '/images/photos/ourprograms/ourprograms_11_2.jpg',
        srcSet: '/images/photos/ourprograms/ourprograms_11_2.jpg',
      },
      title: "Research‎",
      subtitle:
        'We encourage students in our standard program to pursue academic research in high school, and we help connect interested students with dedicated mentors who will guide them through the research process. We also provide help with publication support, so students can see their work published in academic journals and conferences. ',
    },
  ]; 


  export const giftedPrograms = [
    {
      cover: {
        src: '/images/photos/ourprograms/ourprograms_12.png',
        srcSet: '/images/photos/ourprograms/ourprograms_12.png',
      },
      title: 'Support‎',
      subtitle:
      'To ensure each student is progressing well in their studies, our dedicated teachers are there to meet one-on-one in weekly meetings to ensure that the student is understanding the material and not falling behind. Additionally, students are provided with a personal academic counselor, ensuring that students attend with bi-weekly meetings with our credentialed counselors to ensure students are well supported, as well as modify the student’s academic plan.',
    },
    {
      cover: {
        src: '/images/photos/ourprograms/ourprograms_12_2.jpg',
      srcSet: '/images/photos/ourprograms/ourprograms_12_2.jpg',
      },
      title: "Research‎",
      subtitle:
      'For our gifted students, we greatly encourage each and every one to pursue academic research in high school, and we help connect interested students with dedicated mentors who will guide them through the research process. We also provide help with publication support, so students can see their work published in academic journals and conferences. We provide several courses and clubs for gifted students that focus on this research.',
    },
  ]; 