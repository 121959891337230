import { colors } from '@material-ui/core';
import theme from '../../../theme';

export const advantages = [
  {
    key: 'accreditation',
    title: 'Edgenuity Accreditation',
    description:
      'Englewood Academy is proud to be accredited by Edgenuity, offering a top notch curriculum that is both challenging and flexible. Students are expected to devote as much time to their studies as at a brick and mortar school, although they can choose to arrange their study schedule as best suits their needs.',
    icon: '/images/icons/curriculum_02.png',
  },
  {
    key: 'curriculum',
    title: 'Our Own Curriculum',
    description:
      'In addition to offering an Edgenuity curriculum, we’ve created our own, specialized courses to prepare students to excel and thrive in higher institutions of learning. With a focus on Olympiad-style problem solving, academic research, and competition prep, our curriculum exceeds the norm when it comes to preparing students for transitioning into strong academic environments.',
    icon: '/images/icons/curriculum_03.png',
  },
];

export const middleSchoolCurriculum = {
  english: {
    title: 'English/ Language Arts',
    icon: '/images/icons/blue_english.png',
    color: colors.amber,
    items: [
      {
        title: 'Language arts 6',
        grades: '',
      },
      {
        title: 'Language arts 7',
        grades: '',
      },
      {
        title: 'Language arts 8',
        grades: '',
      },
    ],
  },
  mathematics: {
    title: 'Mathematics',
    icon: '/images/icons/blue_math.png',
    color: colors.indigo,
    items: [
      {
        title: 'Math 6',
        grades: '',
      },
      {
        title: 'Math 7',
        grades: '',
      },
      {
        title: 'Math 8',
        grades: '',
      },
      {
        title: 'Algebra 1',
        grades: '',
      },
    ],
  },
  science: {
    title: 'Science',
    icon: '/images/icons/blue_science.png',
    color: colors.deepPurple,
    items: [
      {
        title: 'Science 6, 7 & 8',
        grades: '',
      },
      {
        title: 'Life Science',
        grades: '',
      },
      {
        title: 'Earth & Space Science',
        grades: '',
      },
      {
        title: 'Physical Science',
        grades: '',
      },
    ],
  },
  languages: {
    title: 'World Languages',
    icon: '/images/icons/blue_worldlanguage.png',
    color: colors.blue,
    items: [
      // {
      //   title: 'Chinese 1 and 2',
      //   grades: '',
      // },
      {
        title: 'French 1 and 2',
        grades: '',
      },
      {
        title: 'German 1 and 2',
        grades: '',
      },
      // {
      //   title: 'Latin 1 and 2',
      //   grades: '',
      // },
      {
        title: 'Spanish 1 and 2',
        grades: '',
      },
    ],
  },
  hss: {
    title: 'History and Social Science',
    icon: '/images/icons/blue_history.png',
    color: colors.blue,
    items: [
      {
        title: 'American History before 1865',
        grades: '',
      },
      {
        title: 'American History since 1865',
        grades: '',
      },
      {
        title: 'Intermediate civics and economics',
        grades: '',
      },
      {
        title: 'Intermediate global studies',
        grades: '',
      },
      {
        title: 'World history 1 and 2',
        grades: '',
      },
      {
        title: 'Social Studies 6, 7 & 8',
        grades: '',
      },
    ],
  },
  electives: {
    title: '16 Electives',
    icon: '/images/icons/blue_electives.png',
    color: colors.blue,
    items: [
      {
        title: 'Art',
        grades: '',
      },
      {
        title: 'Music',
        grades: '',
      },
      {
        title: 'Health',
        grades: '',
      },
      {
        title: 'PE',
        grades: '',
      },
      {
        title: 'Computer',
        grades: '',
      },
      // {
      //   title: 'Journalism',
      //   grades: '',
      // },
      // {
      //   title: 'Photography ',
      //   grades: '',
      // },
    ],
  },
};

export const highSchoolCurriculum = [
  {
    title: 'ENGLISH : 12',
    icon: '/images/icons/gold_english.png',
    color: colors.indigo,
  },
  {
    title: 'MATH : 15',
    icon: '/images/icons/gold_math.png',
    color: colors.orange,
  },
  {
    title: 'SCIENCE : 17',
    icon: '/images/icons/gold_science.png',
    color: colors.red,
  },
  {
    title: 'HISTORY & SOCIAL SCIENCE : 15',
    icon: '/images/icons/gold_socail.png',
    color: colors.purple,
  },
  {
    title: 'WORLD LANGUAGES : 7',
    icon: '/images/icons/gold_languages.png',
    color: colors.blue,
  },
  {
    title: 'ELECTIVES : 18',
    icon: '/images/icons/gold_electives.png',
    color: colors.green,
  },
  {
    title: 'CAREER-FOCUSED ELECTIVES : 45',
    icon: '/images/icons/gold_career.png',
    color: colors.pink,
  },
  {
    title: 'Credit Recovery : 47',
    icon: '/images/icons/gold_credit.png',
    color: colors.deepOrange,
  },
];

export const highSchoolDiplomaRequirements = [
  {
    icon: {
      src: '/images/icons/gold_english.png',
      srcSet: '/images/icons/gold_english.png',
    },
    authorName: 'English',
    authorOccupation: '4 credits',
    feedback:
      '',
  },
  {
    icon: {
      src: '/images/icons/gold_math.png',
      srcSet: '/images/icons/gold_math.png',
    },
    authorName: 'Mathematics',
    authorOccupation: '4 credits',
    feedback:
      '',
  },
  {
    icon: {
      src: '/images/icons/gold_socail.png',
      srcSet: '/images/icons/gold_socail.png',
    },
    authorName: 'History and Social Sciences',
    authorOccupation: '3 credits',
    feedback:
      '',
  },
  {
    icon: {
      src: '/images/icons/gold_science.png',
      srcSet: '/images/icons/gold_science.png',
    },
    authorName: 'Natural Sciences',
    authorOccupation: '3 credits',
    feedback:
      '',
  },
  {
    icon: {
      src: '/images/icons/gold_languages.png',
      srcSet: '/images/icons/gold_languages.png',
    },
    authorName: 'Languages',
    authorOccupation: '2 credits',
    feedback:
      '',
  },
  {
    icon: {
      src: '/images/icons/gold_credit.png',
      srcSet: '/images/icons/gold_electives.png',
    },
    authorName: 'Electives',
    authorOccupation: '4 credits',
    feedback:
      '',
  },
];


export const expandededucation = [
  {
    key: 'testpreparation',
    imagesrc: '/images/academics/test.png',
    title: `Test Preparation`,
    subtitle:
      'In addition to our curriculum, we provide our students with test-prep to ensure they are fully prepared for college entrance exams. Through our dedicated tutors, students work one-on-one to gain confidence in both the reading/writing and math portions of the exams, and are motivated to reach their highest scores.',
  },
  {
    key: 'clubsandactivities',
    imagesrc: '/images/academics/clubs.png',
    title: 'Clubs and Activities',
    subtitle:
      'Our academic clubs provide the unique opportunity for gifted students to participate in national and international competitions, such as the International olympiads and Math AMC. In addition to encouraging our students to sign up, we provide support and coaching to ensure our students achieve the best results in these competitions.',
  },
  {
    key: 'careereducation',
    imagesrc: '/images/academics/career.png',
    title: 'Career Education',
    subtitle:
      'We prepare students for a life beyond the classroom. Students take advantage of our variety of clubs and activities to begin to chart their career interests while still in high school. Whether a student is interested in politics, business, or anything in between, we have the resources to help students explore different career pathways so they can best chart their future.',
  },
];