import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  useMediaQuery,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
} from '@material-ui/core';
import { Image } from 'components/atoms';
import { SectionHeader, IconAlternate } from 'components/molecules';

const useStyles = makeStyles(theme => ({
  root: {},
  listItemAvatar: {
    marginRight: theme.spacing(2),
  },
  coverImage: {
    [theme.breakpoints.down('sm')]: {
      maxWidth: 500,
    },
  },
  avatar: {
    background: 'transparent',
    width: 50,
    height: 50,
    borderRadius: theme.spacing(1),
  },
}));

const StandardProgram = props => {
  const { data, className, ...rest } = props;
  const classes = useStyles();

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const primaryText = {
    fontSize: '1.25rem',
    fontWeight: 500,
    color: '#121037',
    marginBottom: 8
  };

  const secondaryText = {
    fontSize: '1rem',
    fontWeight: 400,
    lineHeight: 1.5,
  };

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <SectionHeader
        title={
          <span>
            <span className="text-highlighted__primary">Standard</span>
            {' '}Program
          </span>
        }
        subtitle="For students seeking a quality and convenient high school education, we offer a premier program that is academically challenging and enriching. With a variety of core courses and electives to choose from, our standard diploma program offers a rigorous education that can be earned on a student’s own terms."
        align="left"
        disableGutter
      />
      <br />
      <Grid container spacing={4} direction={isMd ? 'row' : 'column-reverse'}>
        <Grid item xs={12} md={6} data-aos="fade-up">
          
          <List disablePadding>
            {data.map((item, index) => (
              <ListItem disableGutters key={index} data-aos="fade-up">
                <ListItemAvatar className={classes.listItemAvatar}>
                  <Avatar src={item.icon} className={classes.avatar} />
                </ListItemAvatar>
                <ListItemText primary={item.title} primaryTypographyProps={{ style: primaryText }} secondary={item.subtitle} secondaryTypographyProps={{ style: secondaryText }} />
              </ListItem>
            ))}
          </List>
        </Grid>
        <Grid
          item
          container
          justify="center"
          alignItems="center"
          xs={12}
          md={6}
          data-aos="fade-up"
        >
          <Image
            src="/images/how-it-works/standard.png"
            alt="..."
            className={classes.coverImage}
          />
        </Grid>
      </Grid>
    </div>
  );
};

StandardProgram.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
  /**
   * data to be rendered
   */
  data: PropTypes.array.isRequired,
};

export default StandardProgram;
