import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useMediaQuery } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import { Image } from 'components/atoms';
import { CardBase, DescriptionListIcon } from 'components/organisms';
import { useLocation } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  root: {},
  learnMoreLink: {
    marginTop: theme.spacing(2),
    color: theme.palette.secondary.main,
  },
}));

const Advantages = props => {
  const { data, className, ...rest } = props;
  const classes = useStyles();

  const theme = useTheme();
  const isLg = useMediaQuery(theme.breakpoints.up('lg'), {
    defaultMatches: true,
  });

  const location = useLocation();
  // const targetRef = useRef();
  const targetRefArr = {};

  useEffect(() => {

    if ((location.hash === '#accreditation' || location.hash === '#curriculum')) { 
      const bodyRect = document.body.getBoundingClientRect().top;
      const elementPosition = targetRefArr[location.hash.substring(1)].getBoundingClientRect().top;
      const offsetPosition = elementPosition - bodyRect - 150;

      window.scrollTo({
          top: offsetPosition,
          behavior: "smooth"
      });

      // targetRef.current.scrollIntoView({
      //   behavior: 'smooth'
      // });
    }
    
  }, [location.hash]);

  return (
    <div className={clsx(classes.root, className)} data-aos="fade-up" {...rest}>
      <br/>
      <br/>
      <br/>

      <Grid container spacing={isLg ? 10 : 2}>
        {data.map((item, index) => (
          <Grid ref={ref => (targetRefArr[item.key] = ref)} 
            key={index}
            item
            container
            alignItems="center"
            direction="column"
            xs={12}
            sm={6}
            data-aos="fade-up"
          >
            <CardBase liftUp variant="outlined">
              <DescriptionListIcon
                titleVariant={'h5'}
                subtitleVariant={'h6'}
                title={item.title}
                subtitle={item.description}
                icon={
                  <Image
                    src={item.icon}
                    srcSet={item.icon}
                  />
                }
              />
            </CardBase>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

Advantages.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
  /**
   * data to be rendered
   */
  data: PropTypes.array.isRequired,
};

export default Advantages;
