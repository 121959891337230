export const team = [
  {
    title: 'CEO / Co-Founder',
    authorPhoto: {
      src: '/images/photos/people/kate-segelson.jpg',
      srcSet: '/images/photos/people/kate-segelson@2x.jpg 2x',
    },
    authorName: 'Cindy Hwang',
  },
  {
    title: 'CEO / Co-Founder',
    authorPhoto: {
      src: '/images/photos/people/alex-johnson.jpg',
      srcSet: '/images/photos/people/alex-johnson@2x.jpg 2x',
    },
    authorName: 'David Hwang',
  },
  {
    title: 'Advisors',
    authorPhoto: {
      src: '/images/photos/people/valeria-kogan.jpg',
      srcSet: '/images/photos/people/valeria-kogan@2x.jpg 2x',
    },
    authorName: 'Alex Wolf',
  },
  {
    title: 'Web Developer',
    authorPhoto: {
      src: '/images/photos/people/akachi-luccini.jpg',
      srcSet: '/images/photos/people/akachi-luccini@2x.jpg 2x',
    },
    authorName: 'Hyun Cho',
  },
  {
    title: 'CTO / Co-Founder',
    authorPhoto: {
      src: '/images/photos/people/jack-smith.jpg',
      srcSet: '/images/photos/people/jack-smith@2x.jpg 2x',
    },
    authorName: 'Jack Smith',
  },
  {
    title: 'Consultant',
    authorPhoto: {
      src: '/images/photos/people/veronica-adams.jpg',
      srcSet: '/images/photos/people/veronica-adams@2x.jpg 2x',
    },
    authorName: 'Veronica Adams',
  },
];

export const universities = [
  {
    name: 'HARVARD',
    logo: '/images/universities/ivy_1.png',
  },
  {
    name: 'CORNELL',
    logo: '/images/universities/ivy_2.png',
  },
  {
    name: 'YALE',
    logo: '/images/universities/ivy_3.png',
  },
  {
    name: 'PRINCETON',
    logo: '/images/universities/ivy_4.png',
  },
  {
    name: 'STANFORD',
    logo: '/images/universities/ivy_5.png',
  },
  {
    name: 'PENN',
    logo: '/images/universities/ivy_6.png',
  },
  {
    name: 'DARTMOUTH',
    logo: '/images/universities/ivy_7.png',
  },
  {
    name: 'COLUMBIA',
    logo: '/images/universities/ivy_8.png',
  },
];

export const mapData = [
  {
    location: {
      y: 40.8696625,
      x: -73.9582234,
      address: '140 Sylvan Avenue Englewood Cliffs, NJ 07632',
    },
  },
];

export const gallery = [
  {
    
    src: '/images/photos/landing/4_gallery_1_460.png',
    srcSet: '/images/photos/landing/4_gallery_1.png',
    title: 'gallery',
    cols: 1,
  },
  {
    src: '/images/photos/landing/4_gallery_2.png',
    srcSet: '/images/photos/landing/4_gallery_2.png',
    title: 'gallery',
    cols: 3,
  },
  {
    src: '/images/photos/landing/4_gallery_3.png',
    srcSet: '/images/photos/landing/4_gallery_3.png',
    title: 'gallery',
    cols: 3,
  },
  {
    src: '/images/photos/landing/4_gallery_4_460.png',
    srcSet: '/images/photos/landing/4_gallery_4.png',
    title: 'gallery',
    cols: 1,
  },
];
