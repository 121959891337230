import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  useMediaQuery,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  colors,
  Avatar,
} from '@material-ui/core';
import { Image } from 'components/atoms';
import { SectionHeader, IconAlternate } from 'components/molecules';

const useStyles = makeStyles(theme => ({
  root: {},
  listItemAvatar: {
    marginRight: theme.spacing(2),
  },
  coverImage: {
    [theme.breakpoints.down('sm')]: {
      maxWidth: 500,
    },
  },
  avatar: {
    background: 'transparent',
    width: 50,
    height: 50,
    borderRadius: theme.spacing(1),
  },
}));

const GiftedProgram = props => {
  const { data, className, ...rest } = props;
  const classes = useStyles();

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const primaryText = {
    fontSize: '1.25rem',
    fontWeight: 500,
    color: '#121037',
    marginBottom: 8
  };

  const secondaryText = {
    fontSize: '1rem',
    fontWeight: 400,
    lineHeight: 1.5,
  };

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <SectionHeader
        title={
          <span>
            <span className="text-highlighted__primary">Gifted</span>
            {' '}Program
          </span>
        }
        subtitle="At Englewood Online High School, we understand that gifted students can often feel constrained in a brick and mortar school environment. Too often, talented students are forced to relearn material that they’ve already mastered, or sit through classes that move too slowly for them. Our gifted program is tailored to each individual student’s needs, providing challenging coursework and opportunities with gifted children in mind, such as independent study and guided research opportunities."
        align="left"
        disableGutter
      />
      <Grid container spacing={4}>
        <Grid
          item
          container
          justify="center"
          alignItems="center"
          xs={12}
          md={6}
          data-aos="fade-up"
        >
          <Image
            src="/images/how-it-works/gifted.png"
            alt="..."
            className={classes.coverImage}
          />
        </Grid>
        <Grid item xs={12} md={6} data-aos="fade-up">
          <List disablePadding>
            {data.map((item, index) => (
              <ListItem disableGutters key={index} data-aos="fade-up">
                <ListItemAvatar className={classes.listItemAvatar}>
                  <Avatar src={item.icon} className={classes.avatar} />
                </ListItemAvatar>
                <ListItemText primary={item.title} primaryTypographyProps={{ style: primaryText }} secondary={item.subtitle} secondaryTypographyProps={{ style: secondaryText }} />
              </ListItem>
            ))}
          </List>
        </Grid>
      </Grid>
    </div>
  );
};

GiftedProgram.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
  /**
   * data to be rendered
   */
  data: PropTypes.array.isRequired,
};

export default GiftedProgram;
