import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useMediaQuery, Grid, Typography, Button } from '@material-ui/core';
import { SectionHeader } from 'components/molecules';
import { CardBase, DescriptionListIcon, CardProduct } from 'components/organisms';
import { Image } from 'components/atoms';

const useStyles = makeStyles((theme) => ({
  root: {},
  fontWeightBold: {
    fontWeight: 'bold',
  },
  card: {
    borderTop: `4px solid ${theme.palette.secondary.main}`,
  },
  image: {
    objectFit: 'cover',
    borderRadius: theme.spacing(0, 0, 0,20),
  },
}));

const ExpandedEducation = props => {
  const { data, className, ...rest } = props;
  const classes = useStyles();

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <SectionHeader
        title="Expanded Education"
        subtitle="One of the intrinsic attractions of online schooling has to be diversity. We offer not only rigorous required courses and challenging AP and Honor courses, but also a wide selection of electives. We have more than 50 academic and career focused electives, such as Business and IT, Medical assistant, and Computer management with certificate prep. Our intensive electives will help students expand their educational horizons and future career possibilities."
        align="center"
        data-aos="fade-up"
        titleVariant="h3"
      />
      <Grid container spacing={isMd ? 4 : 2}>
        {data.map((item, index) => (
          <Grid item xs={12} md={4} key={index} data-aos="fade-up" >
            <CardProduct liftUp noShadow variant="outlined"
              mediaContent={
                  <Image
                    src={item.imagesrc}
                    srcSet={item.imagesrc}
                    lazyProps={{ width: '100%', height: '100%' }}
                    className={clsx(
                      classes.image,
                    )}
                  />
              }
              cardContent={
                <DescriptionListIcon
                  titleVariant='h5'
                  title={item.title}
                  subtitleVariant='h6'
                  subtitle={item.subtitle}
                  align="left"
                />
              }
            >
            </CardProduct>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

ExpandedEducation.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
};

export default ExpandedEducation;
