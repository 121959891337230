import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { 
  useMediaQuery,
  AppBar,
  Toolbar,
  Drawer,
  IconButton,
  Divider, } from '@material-ui/core';
import ForumIcon from '@material-ui/icons/Forum';
import { ContactForm } from 'common';
import { Topbar, Footer, Sidebar } from './components';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
  },
  appBarBottom: {
    top: 'auto',
    bottom: 0,
    background: 'transparent',
    boxShadow: 'none',
  },
  toolbarBottom: {
    width: '100%',
    margin: '0 auto',
    padding: theme.spacing(0, 2),
  },
  chatIconButton: {
    position: 'absolute',
    right: theme.spacing(3),
    left: 'auto',
    top: theme.spacing(-3),
    background: theme.palette.primary.main,
    width: 55,
    height: 55,
    boxShadow: '0 2px 10px 0 rgba(23,70,161,.11)',
    '&:hover': {
      background: theme.palette.primary.main,
    },
  },
  forumIcon: {
    color: 'white',
    width: 25,
    height: 25,
  },
  contactForm: {
    padding: theme.spacing(3, 2),
    maxWidth: 800,
    margin: '0 auto',
  },
}));

const Main = props => {
  const { children } = props;

  const classes = useStyles();

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  

  const pages = {
    howitworks: {
      title: 'How It Works',
      id: 'howitworks-pages',
      href: '/how-it-works',
      children: {
        etc: {
          groupTitle: 'How it works',
          pages: [
            {
              title: 'Mission Statement',
              href: '/how-it-works',
            },
            {
              title: 'How Englewood OHS Works',
              href: '/how-it-works#englewood-ohs',
            },
            {
              title: 'Our Three Programs',
              href: '/how-it-works#three-programs',
            },
          ],
        },
      },
    },
    admissions: {
      title: 'Admissions',
      id: 'admissions-pages',
      href: '/admissions',
      children: {
        etc: {
          groupTitle: 'Admissions',
          pages: [
            {
              title: 'Admission Process',
              href: '/admissions',
            },
            {
              title: 'Middle School Tuition',
              href: '/admissions#middle-school-tuitions',
            },
            {
              title: 'High School Tuition',
              href: '/admissions#high-school-tuitions',
            },
          ],
        },
      },
    },
    academics: {
      title: 'Academics',
      id: 'academics-pages',
      href: '/academics',
      children: {
        etc: {
          groupTitle: 'Academics',
          pages: [
            {
              title: 'Curriculum Overview',
              href: '/academics',
            },
            {
              title: 'Middle School Curriculum',
              href: '/academics#middle-school-curriculum',
            },
            {
              title: 'High School Curriculum',
              href: '/academics#high-school-curriculum',
            },
            {
              title: 'Live Teaching & Tutoring',
              href: '/academics#live-teaching-and-tutoring',
            },
            {
              title: 'High School Research Classes',
              href: '/academics#high-school-research-classes',
            },
          ],
        },
      },
    },
    studentlife: {
      title: 'Student Life',
      id: 'studentLife-pages',
      href: '/student-life',
      children: {
        etc: {
          groupTitle: 'Student Life',
          pages: [
            {
              title: 'Sample Schedules',
              href: '/student-life',
            },
            {
              title: 'College Counseling',
              href: '/student-life#college-counseling',
            },
            {
              title: 'Clubs & Extracurricular Activities',
              href: '/student-life#clubs-and-extracurricular-activities',
            },
          ],
        },
      },
    },
    aboutus: {
      title: 'About Us',
      id: 'aboutUs-pages',
      href: '/about-us',
      children: {
        etc: {
          groupTitle: 'About Us',
          pages: [
            {
              title: 'About Us',
              href: '/about-us',
            },
            {
              title: 'Our Team',
              href: '/about-us#our-team',
            },
          ],
        },
      },
    },
  };

  const [openSidebar, setOpenSidebar] = useState(false);
  const [openBottombar, setOpenBottombar] = useState(false);

  const handleSidebarOpen = () => {
    setOpenSidebar(true);
  };

  const handleSidebarClose = () => {
    setOpenSidebar(false);
  };
  

  const handleBottombarOpen = () => {
    setOpenBottombar(true);
  };

  const handleBottombarClose = () => {
    setOpenBottombar(false);
  };

  const open = isMd ? false : openSidebar;

  return (
    <div
      className={clsx({
        [classes.root]: true,
      })}
    >
      
      <Topbar onSidebarOpen={handleSidebarOpen} pages={pages} />
      <Sidebar
        onClose={handleSidebarClose}
        open={open}
        variant="temporary"
        pages={pages}
      />
      <main>{children}</main>
      <Divider />
      <AppBar position="fixed" className={classes.appBarBottom}>
        <Toolbar disableGutters className={classes.toolbarBottom}>
          <IconButton
            className={classes.chatIconButton}
            onClick={handleBottombarOpen}
          >
            <ForumIcon className={classes.forumIcon} />
          </IconButton>
          <Drawer
            anchor="bottom"
            open={openBottombar}
            onClose={handleBottombarClose}
          >
            <div className={classes.contactForm}>
              <ContactForm drawerHandle={handleBottombarClose}/>
            </div>
          </Drawer>
        </Toolbar>
      </AppBar>
      <Footer pages={pages} />
    </div>
  );
};

Main.propTypes = {
  children: PropTypes.node,
};

export default Main;
