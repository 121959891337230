import React, { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useMediaQuery, Grid } from '@material-ui/core';
import { Image } from 'components/atoms';
import { SectionHeader } from 'components/molecules';

const useStyles = makeStyles(() => ({
    root: {},
    image: {
        maxWidth: 420,
    },
}));

const OurTeachers = props => {
    const { className, ...rest } = props;
    const classes = useStyles();

    const theme = useTheme();
    const isMd = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true,
    });

    const location = useLocation();
    const targetRef = useRef();

    useEffect(() => {
        if (targetRef && location.hash === '#our-team') {
            const bodyRect = document.body.getBoundingClientRect().top;
            const elementPosition = targetRef.current.getBoundingClientRect().top;
            const offsetPosition = elementPosition - bodyRect - 150;

            window.scrollTo({
                top: offsetPosition,
                behavior: "smooth"
            });

            // targetRef.current.scrollIntoView({
            //   behavior: 'smooth'
            // });
        }
    }, [location.hash]);

    return (
        <div ref={targetRef} className={clsx(classes.root, className)} {...rest}>
            <Grid
                container
                justify="space-between"
                spacing={isMd ? 4 : 2}
                direction={isMd ? 'row' : 'column-reverse'}
            >
                <Grid
                    item
                    container
                    alignItems="center"
                    justify="flex-start"
                    xs={12}
                    md={6}
                    data-aos={'fade-up'}
                >
                    <div>
                        <SectionHeader
                            title="Our Teachers"
                            subtitle="Our teachers are chosen for their passion and expertise, and are fully prepared to challenge students in an online learning environment."
                            align="left"
                            disableGutter
                            subtitleProps={{
                                color: 'textPrimary',
                                variant: 'body1',
                                // variant: 'h6',
                            }}
                        />
                    </div>
                </Grid>
                <Grid
                    item
                    container
                    justify={isMd ? 'flex-end' : 'flex-start'}
                    alignItems="center"
                    xs={12}
                    md={6}
                    data-aos={'fade-up'}
                >
                    <Image
                        src="/images/about-us/aboutus_02.png"
                        srcSet="/images/about-us/aboutus_02.png"
                        alt="Our story"
                        className={classes.image}
                    />
                </Grid>
            </Grid>
        </div>
    );
};

OurTeachers.propTypes = {
    /**
     * External classes
     */
    className: PropTypes.string,
};

export default OurTeachers;
