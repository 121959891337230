import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Grid, Button, useMediaQuery, Card, CardMedia } from '@material-ui/core';
import { Image } from 'components/atoms';
import { SectionHeader } from 'components/molecules';
import { DescriptionListIcon } from 'components/organisms';

const useStyles = makeStyles(theme => ({
  root: {},
  card: {
    boxShadow: 'none',
    border: 0,
    borderLeft: `0px solid ${theme.palette.secondary.main}`,
    maxWidth: 500,
  },
  cardMedia: {
    height: 270,
    [theme.breakpoints.up('md')]: {
      height: 300,
    },
  },
}));

const WhoWeAre = props => {
  const { className, ...rest } = props;
  const classes = useStyles();

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <SectionHeader title="Who We Are" label="Our Story" data-aos="fade-up" />
      <Grid container justify="center" spacing={isMd ? 4 : 2}>
        <Grid item xs={12} sm={5} data-aos={'fade-up'}>
          <DescriptionListIcon
            title="About Us"
            subtitle="At Englewood Online High School, we prepare students to thrive in the global 21st century world, whether preparing them for college or for stepping into the working world. Using our premier, asynchronous curriculum, ..."
            icon={
              <Card className={classes.card}>
                <CardMedia className={classes.cardMedia}>
                  <Image
                    src={'/images/landing/5_whoweare_about_img.png'}
                    srcSet={'/images/landing/5_whoweare_about_img.png'}
                        lazyProps={{ width: '100%', height: '100%' }}
                  />
                </CardMedia>
              </Card>
            }
            align="left"
          />
        </Grid>
        <Grid item xs={12} sm={5} data-aos={'fade-up'}>
          <DescriptionListIcon
            title="Our Teachers"
            subtitle="Our certified instructors are chosen for their passion and expertise, and are fully prepared to challenge students in an online learning environment."
            icon={
              <Card className={classes.card}>
                <CardMedia className={classes.cardMedia}>
                  <Image
                    src={'/images/landing/5_whoweare_teacher_img.png'}
                    srcSet={'/images/landing/5_whoweare_teacher_img.png'}
                        lazyProps={{ width: '100%', height: '100%' }}
                  />
                </CardMedia>
              </Card>
            }
            align="left"
          />
        </Grid>
        <Grid item container justify="center" xs={12} data-aos={'fade-up'}>
          <Button variant="outlined" color="primary" href="/about-us">
            Learn more
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

WhoWeAre.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
};

export default WhoWeAre;
