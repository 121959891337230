import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useLocation } from 'react-router-dom';
import {
  useMediaQuery,
  Grid,
  Typography,
  colors,
} from '@material-ui/core';
import { Icon } from 'components/atoms';
import { SectionHeader } from 'components/molecules';
import { CardPricingTwo } from 'components/organisms';

const useStyles = makeStyles(theme => ({
  root: {},
  fontWeight300: {
    fontWeight: 300,
  },
  fontWeight400: {
    fontWeight: 400,
  },
  fontWeight600: {
    fontWeight: 600,
    color: theme.palette.primary.main,
  },
}));

const MiddleSchoolPricings = props => {
  const { className, ...rest } = props;
  const classes = useStyles();

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const location = useLocation();
  const targetRef = useRef();

  useEffect(() => {
    if (targetRef && location.hash ==='#middle-school-tuitions') { 
      const bodyRect = document.body.getBoundingClientRect().top;
      const elementPosition = targetRef.current.getBoundingClientRect().top;
      const offsetPosition = elementPosition - bodyRect - 150;

      window.scrollTo({
          top: offsetPosition,
          behavior: "smooth"
      }); 
      
      // targetRef.current.scrollIntoView({
      //   behavior: 'smooth'
      // });
    }
  }, [location.hash]);

  return (
    <div ref={targetRef} className={clsx(classes.root, className)} {...rest}>
      <SectionHeader
        title={ 
          <span>
            Middle School Tuition
          </span>
        }
        titleVariant="h3"
        subtitle=""
        subtitleProps={{ variant: 'h6', align: 'left' }}
        data-aos="fade-up"
        disableGutter
      />
      <Grid item xs={12} data-aos='fade-up'>
        <Typography
          variant="h6"
          component="p"
          align='center'
        >
          6th to 8th Grades
        </Typography>
      </Grid>
      <br />
      <br />
      <Grid container spacing={isMd ? 4 : 2}>
        <Grid item xs={12} md={6} data-aos="fade-up">
          <CardPricingTwo style={{ borderTop: `7px solid ${colors.indigo[500]}` }}
            variant="outlined"
            withShadow
            liftUp
            title="Standard"
            titleProps={{ variant: 'h4', align: 'center' }}
            priceComponent={
              <div>
                <Typography variant="h6" align='center'>
                  Full academic year
                </Typography>
                <Typography variant="h4" align='center'
                  className={classes.fontWeight600}
                >
                  $5,000
                </Typography>
              </div>
            }
            features={[]}
            featureCheckComponent={
              <Icon
                fontIconClass="far fa-check-circle"
                fontIconColor={colors.indigo[500]}
              />
            }
            priceComponent2={
              <div>
                <Typography variant="h6" align='center'>
                  Single semester
                </Typography>
                <Typography variant="h4" align='center'
                  className={classes.fontWeight600}
                >
                  $2,750
                </Typography>
              </div>
            }
            features2={[]}
            featureCheckComponent2={
              <Icon
                fontIconClass="far fa-check-circle"
                fontIconColor={colors.indigo[500]}
              />
            }
            cta={
              <></>
            }
            disclaimer=""
          />
        </Grid>
        <Grid item xs={12} md={6} data-aos="fade-up">
          <CardPricingTwo style={{ borderTop: `7px solid ${colors.indigo[500]}` }}
            variant="outlined"
            title="Gifted"
            titleProps={{ variant: 'h4', align: 'center' }}
            liftUp
            subtitle=""
            priceComponent={
              <div>
                <Typography variant="h6" align='center'>
                  Full academic year
                </Typography>
                <Typography variant="h4" align='center'
                  className={classes.fontWeight600}
                >
                  $6,000
                </Typography>
              </div>
            }
            features={[]}
            featureCheckComponent={
              <Icon
                fontIconClass="far fa-check-circle"
                fontIconColor={colors.indigo[500]}
              />
            }
            priceComponent2={
              <div>
                <Typography variant="h6" align='center'>
                  Single semester
                </Typography>
                <Typography variant="h4" align='center'
                  className={classes.fontWeight600}
                >
                  $3,500
                </Typography>
              </div>
            }
            features2={[]}
            featureCheckComponent2={
              <Icon
                fontIconClass="far fa-check-circle"
                fontIconColor={colors.indigo[500]}
              />
            }
            cta={
              <></>
            }
            disclaimer=""
          />
        </Grid>
      </Grid>
      <div style={{ paddingTop: 20, paddingBottom: 10 }} data-aos="fade-up">
        <Typography variant="h6" color="textSecondary" component="p" align="center">
          Full time students (typically 5 - 6 courses)
        </Typography>
      </div>
    </div>
  );
};

MiddleSchoolPricings.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
};

export default MiddleSchoolPricings;
