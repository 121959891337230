import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useMediaQuery, Grid, Button } from '@material-ui/core';
import { Image } from 'components/atoms';
import { SectionHeader } from 'components/molecules';

const useStyles = makeStyles(theme => ({
  root: {},
  image: {
    [theme.breakpoints.down('sm')]: {
      maxWidth: 500,
    },
  },
}));

const AthleteAndPerformerProgramHero = props => {
  const { className, ...rest } = props;
  const classes = useStyles();

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Grid container justify="space-between" spacing={isMd ? 4 : 2}>
        <Grid item xs={12} md={6} data-aos={'fade-up'}>
          <SectionHeader
            title={
              <span>
                <span className="text-highlighted__primary">Student Athlete & Performer</span>
                {' '}Program
              </span>
            }
            subtitle="For those pursuing an outside sport, dance, or acting career, our Student athlete and Performer program combines a rigorous curriculum with a flexible, self-paced schedule, allowing for students to fully tailor their academic experience around their passions. This program is perfect for students who seek an education without disrupting their busy non-academic schedules."
            align={isMd ? 'left' : 'center'}
            disableGutter
            titleVariant="h4"
          />
        </Grid>
        <Grid
          item
          container
          justify="center"
          alignItems="center"
          xs={12}
          md={6}
          data-aos={'fade-up'}
        >
          <Image
            src="/images/how-it-works/athleteperformer.png"
            srcSet="/images/how-it-works/athleteperformer.png"
            alt="Englewood OHS"
            className={classes.image}
          />
        </Grid>
      </Grid>
    </div>
  );
};

AthleteAndPerformerProgramHero.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
};

export default AthleteAndPerformerProgramHero;
