import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useLocation } from 'react-router-dom';
import {
  useMediaQuery,
  colors,
  Grid,
  Typography,
} from '@material-ui/core';
import { Icon } from 'components/atoms';
import { SectionHeader } from 'components/molecules';
import { CardPricingTwo } from 'components/organisms';

const useStyles = makeStyles((theme) => ({
  root: {},
  fontWeight400: {
    fontWeight: 400,
  },
  fontWeight600: {
    fontWeight: 600,
    color: theme.palette.gold,
  },
  titleClasses: {
    height: 80,
  },
}));

const HighSchoolPricings = props => {
  const { data, className, ...rest } = props;
  const classes = useStyles();

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });
  const isSm = useMediaQuery(theme.breakpoints.up('960'), {
    defaultMatches: true,
  });

  const location = useLocation();
  const targetRef = useRef();

  useEffect(() => {
    if (targetRef && location.hash ==='#high-school-tuitions') { 
      const bodyRect = document.body.getBoundingClientRect().top;
      const elementPosition = targetRef.current.getBoundingClientRect().top;
      const offsetPosition = elementPosition - bodyRect - 150;

      window.scrollTo({
          top: offsetPosition,
          behavior: "smooth"
      }); 
      
      // targetRef.current.scrollIntoView({
      //   behavior: 'smooth'
      // });
    }
  }, [location.hash]);

  return (
    <div ref={targetRef} className={clsx(classes.root, className)} {...rest}>
      <SectionHeader
        title={ 
          <span>
            High School Tuition
          </span>
        }
        titleVariant="h3"
        subtitle=""
        subtitleProps={{ variant: 'h6', align: 'left' }}
        data-aos="fade-up"
        disableGutter
      />
      <Grid item xs={12} data-aos='fade-up'>
        <Typography
          variant="h6"
          component="p"
          align='center'
        >
          9th to 12th Grades
        </Typography>
      </Grid>
      <br />
      <br />
      <Grid container spacing={isMd ? 4 : 2}>
        {data.map((item, index) => (
          <Grid item xs={12} md={4} data-aos="fade-up" key={index}>
            <CardPricingTwo style={{ borderTop: `7px solid #CE9A1A` }}
              variant="outlined"
              withShadow={item.isHighlighted ? true : false}
              liftUp
              title={item.title}
              titleHeight = {item.title !== "Athlete & Performer" && isSm}
              titleProps={{ variant: 'h4', align: 'center' }}
              priceComponent={
                <div>
                  <Typography variant="h6" align='center'>
                    {item.pricePrefix1}
                  </Typography>
                  <Typography
                    variant="h4" align='center'
                    className={classes.fontWeight600}
                  >
                    {item.price}
                  </Typography>
                </div>
              }
              features={item.features}
              featureCheckComponent={
                <Icon
                  fontIconClass="far fa-check-circle"
                  fontIconColor={theme.palette.text.secondary}
                />
              }
              priceComponent2={
                <div>
                  <Typography variant="h6" align='center'>
                    {item.pricePrefix2}
                  </Typography>
                  <Typography
                    variant="h4" align='center'
                    className={classes.fontWeight600}
                  >
                    {item.price2}
                  </Typography>
                </div>
              }
              features2={item.features2}
              featureCheckComponent2={
                <Icon
                  fontIconClass="far fa-check-circle"
                  fontIconColor={colors.indigo[500]}
                />
              }
              cta={
                <></>
              }
              disclaimer=""
            />
          </Grid>
        ))}
      </Grid>
      <div style={{ paddingTop: 20, paddingBottom: 10 }} data-aos="fade-up">
        <Typography variant="h6" color="textSecondary" component="p" align="center">
          Full time students (typically 5 - 6 courses)
        </Typography>
      </div>
      <br />
      <Typography variant="h6" color='textSecondary'>
        <span style={{ color:'#CE9A1A' }}>*</span> Research courses are available at $3,000 for public and at $2,500 for Englewood Online High School Student Athlete & Performer Program or Standard Program Full academic year students.
      </Typography>
      <Typography variant="h6" color='textSecondary'>
        <span style={{ color:'#CE9A1A' }}>*</span> AP, Credit recovery, and other regular courses are available to part time students per course registration basis, starting $1,100 for a full academic year. 
      </Typography>
    </div>
  );
};

HighSchoolPricings.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
  /**
   * data to be rendered
   */
  data: PropTypes.array.isRequired,
};

export default HighSchoolPricings;
