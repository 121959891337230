import React, { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useMediaQuery } from '@material-ui/core';
import { SectionHeader } from 'components/molecules';
import { CardBase } from 'components/organisms';

const useStyles = makeStyles(theme => ({
  root: {},
  card: {
    borderBottom: `5px solid ${theme.palette.secondary.main}`,
    padding: theme.spacing(0, 3),
  },
}));

const LiveTeachingAndTutoring = props => {
  const { className, ...rest } = props;
  const classes = useStyles();

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const location = useLocation();
  const targetRef = useRef();

  useEffect(() => {
    if (targetRef && location.hash === '#live-teaching-and-tutoring') { 
      const bodyRect = document.body.getBoundingClientRect().top;
      const elementPosition = targetRef.current.getBoundingClientRect().top;
      const offsetPosition = elementPosition - bodyRect - 150;

      window.scrollTo({
          top: offsetPosition,
          behavior: "smooth"
      });   

      // targetRef.current.scrollIntoView({
      //   block: "start",
      //   behavior: 'smooth'
      // });
    }
  }, [location.hash]);

  return (
    <div ref={targetRef} className={clsx(classes.root, className)} {...rest}>
      <CardBase withShadow liftUp className={classes.card}>
        <SectionHeader disableGutter
          label="Englewood OHS" 
          title="Live Teaching and Tutoring" 
          subtitle="Unlike many other online schools that offer little support beyond asynchronous lessons, Englewood Online High School goes the extra mile on top of already great virtual lessons. We offer regular and on demand live teaching sessions to ensure our students have the support they need to thrive in the virtual classroom. Students can attend weekly virtual office hours to enrich their learning experience and even request one-on-one Zoom  sessions with our highly qualified teachers. When live meetings are not possible, students can communicate with their teachers through email."
          subtitleProps={{ align: 'left' }}
          titleVariant="h3"
        />
      </CardBase>
    </div>
  );
};

LiveTeachingAndTutoring.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
  
};

export default LiveTeachingAndTutoring;
