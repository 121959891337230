import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  AppBar,
  Toolbar,
  Hidden,
  List,
  ListItem,
  Typography,
  IconButton,
  colors,
} from '@material-ui/core';

import MenuIcon from '@material-ui/icons/Menu';
import { Image } from 'components/atoms';

const useStyles = makeStyles(theme => ({
  root: {
    background: theme.palette.white,

    [theme.breakpoints.up('md')]: {
      boxShadow: 'none',
      background: theme.palette.info.dark,
      borderBottom: `1px solid ${colors.grey[200]}`,
      height: 45,
    },
  },
  flexGrow: {
    flexGrow: 1,
  },
  navigationContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  toolbar: {
    maxWidth: 1280,
    minHeight: 45,
    width: '100%',
    margin: '0 auto',
    padding: theme.spacing(0, 2),
  },
  listItem: {
    cursor: 'pointer',
    '&:hover > .menu-item, &:hover svg': {
      color: theme.palette.primary.dark,
    },
  },
  listItemText: {
    flex: '0 0 auto',
    marginRight: theme.spacing(2),
    whiteSpace: 'nowrap',
  },
  iconButton: {
    padding: 0,
    '&:hover': {
      background: 'transparent',
    },
  },
  logoContainer: {
    width: 110,
    height: 40,
    [theme.breakpoints.up('md')]: {
      width: 0,
      height: 0,
    },
  },
  logoImage: {
    width: '100%',
    height: '100%',
  },
}));



const Topbar1 = props => {
  const { className, onSidebarOpen, pages, ...rest } = props;

  const classes = useStyles();

  
  
  return (
    <AppBar
      {...rest}
      position="relative"
      className={clsx(classes.root, className)}
    >
      <Toolbar disableGutters className={classes.toolbar}>
      <div className={classes.logoContainer}>
          <a href="/" title="thefront">
            <Image
              className={classes.logoImage}
              src="/images/logos/New-logo-transparent.png"
              alt="thefront"
              lazy={false}
            />
          </a>
        </div>
        <div className={classes.flexGrow} />
        <Hidden smDown>
            <List className={classes.navigationContainer}>
                <ListItem className={classes.listItem}>
                    <Typography
                        variant="body1"
                        color="inherit"
                        className={classes.listItemText}
                        component="a"
                        target="blank"
                        href="https://accelerate-englewood.vschool.com"
                    >
                        Log in
                    </Typography>
                </ListItem>
                <ListItem className={classes.listItem}>
                    <Typography
                        variant="body1"
                        color="inherit"
                        className={classes.listItemText}
                        component="a"
                        href="tel:201-408-2236"
                    >
                        (201) 408-2236
                    </Typography>
                </ListItem>
                <ListItem className={classes.listItem}>
                    <Typography
                        variant="body1"
                        color="inherit"
                        className={classes.listItemText}
                        component="a"
                        target="blank"
                        href="#"
                    >
                        Contact us
                    </Typography>
                </ListItem>
            </List>
        </Hidden>
        <Hidden mdUp>
          <IconButton
            className={classes.iconButton}
            onClick={onSidebarOpen}
            aria-label="Menu"
          >
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

Topbar1.propTypes = {
  className: PropTypes.string,
  onSidebarOpen: PropTypes.func,
  pages: PropTypes.object.isRequired,
};

export default Topbar1;
