import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useLocation } from 'react-router-dom';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useMediaQuery, Grid, colors, Button } from '@material-ui/core';
import { LearnMoreLink, Image } from 'components/atoms';
import { SectionHeader, IconAlternate } from 'components/molecules';
import { CardBase, DescriptionListIcon } from 'components/organisms';

const useStyles = makeStyles(theme => ({
  root: {},
  learnMoreLink: {
    marginTop: theme.spacing(2),
    color: theme.palette.secondary.main,
  },
}));

const HowEnglewoodOHSWorks = props => {
  const { data, className, ...rest } = props;
  const classes = useStyles();
  const location = useLocation();
  const targetRef = useRef();

  const theme = useTheme();
  const isLg = useMediaQuery(theme.breakpoints.up('lg'), {
    defaultMatches: true,
  });

  useEffect(() => {
    if (targetRef && location.hash === '#englewood-ohs') { 
      const bodyRect = document.body.getBoundingClientRect().top;
      const elementPosition = targetRef.current.getBoundingClientRect().top;
      const offsetPosition = elementPosition - bodyRect - 150;

      window.scrollTo({
          top: offsetPosition,
          behavior: "smooth"
      }); 

      // targetRef.current.scrollIntoView({
      //   behavior: 'smooth'
      // });
    }
  }, [location.hash]);

  return (
    <div ref={targetRef} className={clsx(classes.root, className)} data-aos="fade-up" {...rest}>
      <SectionHeader
        title={
          <span>
            How Englewood OHS works{' '}
          </span>
        }
        titleProps={{ variant: 'h3' }}
        subtitle="Our friendly and knowledgeable admissions advisors will happily discuss you and your child’s  educational and personal goals. You will be guided every step of the way, from the initial application, to enrolling in your first semester of classes."
        ctaGroup={[(
          <Button variant="contained" size="large" color="secondary" href="/admissions">
            GET STARTED
          </Button>
          )]}
        fadeUp
      />
      
      <Grid container spacing={isLg ? 10 : 2}>
        {data.map((item, index) => (
          <Grid
            key={index}
            item
            container
            alignItems="center"
            direction="column"
            xs={12}
            sm={6}
            data-aos="fade-up"
          >
            <CardBase liftUp variant="outlined">
              <DescriptionListIcon
                title={item.title}
                subtitle={item.description}
                icon={
                  <Image
                    src={item.icon}
                    srcSet={item.icon}
                  />
                }
              />
              { item.learnMore === 'true' && 
                <LearnMoreLink
                  title="Learn more"
                  variant="body1"
                  className={classes.learnMoreLink}
                  color="secondary"
                  href={item.learnMoreLink}
                />
              }
            </CardBase>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

HowEnglewoodOHSWorks.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
  /**
   * data to be rendered
   */
  data: PropTypes.array.isRequired,
};

export default HowEnglewoodOHSWorks;
