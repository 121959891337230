import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';

import { Grid } from '@material-ui/core';
import { SectionHeader, OverlapedImages } from 'components/molecules';

const useStyles = makeStyles(() => ({
  root: {},
}));

const IndividualAttention = props => {
  const { className, ...rest } = props;
  const classes = useStyles();

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={6}>
          <SectionHeader
            label=""
            title={
              <span>
                <span className="text-highlighted">Individual Attention </span>
                means suited to their needs.
              </span>
            }
            subtitle="Unlike a brick and mortar school, we don’t let our students get left behind in the crowd. Our passionate teaching staff work closely with each student to ensure that they have a full grasp of the course material. We do not believe that “one size fits all” when it comes to education. We believe the best way for a student to learn is with individualized attention and support, suited to their needs.
            "
            align="left"
            fadeUp
            disableGutter
          />
        </Grid>
        <Grid item xs={12} sm={6} data-aos="fade-up">
          <OverlapedImages
            image1={{
              src: '/images/photos/missionstatement/mission_02.png',
              srcSet: '/images/photos/missionstatement/mission_02.png',
              alt: '...',
            }}
            image2={{
              src: '/images/photos/missionstatement/mission_03.png',
              srcSet: '/images/photos/missionstatement/mission_03.png',
              alt: '...',
            }}
            image3={{
              src: '/images/photos/missionstatement/mission_04.png',
              srcSet: '/images/photos/missionstatement/mission_04.png',
              alt: '...',
            }}
          />
        </Grid>
      </Grid>
    </div>
  );
};

IndividualAttention.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
};

export default IndividualAttention;
