import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  useMediaQuery,
  colors,
  Grid,
  Card,
  CardMedia,
  CardContent,
  Typography,
} from '@material-ui/core';
import { Image } from 'components/atoms';
import { SectionHeader } from 'components/molecules';
import { useLocation } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  root: {},
  disclimer: {
    borderRadius: theme.spacing(2),
    padding: theme.spacing(1 / 2, 1),
    background: theme.palette.alternate,
    display: 'inline-block',
  },
  card: {
    boxShadow: '0 9px 18px 0 rgba(0, 0, 0, 0.1)',
    borderRadius: theme.spacing(2),
  },
  cardMedia: {
    height: 250,
    padding: theme.spacing(0, 0, 0, 0),
    position: 'relative',
    background: colors.indigo[50],
    [theme.breakpoints.up('md')]: {
      height: 290,
    }
  },
  cardContent: {
    padding: theme.spacing(3),
  },
  image: {
    objectFit: 'cover',
  },
  fontWeightBold: {
    fontWeight: 'bold',
  },
  priceCta: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));

const Products = props => {
  const { data, className, ...rest } = props;
  const classes = useStyles();
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const location = useLocation();
  const targetRef = useRef();

  useEffect(() => {
    if (targetRef && location.hash ==='#tuitions') { 
      const bodyRect = document.body.getBoundingClientRect().top;
      const elementPosition = targetRef.current.getBoundingClientRect().top;
      const offsetPosition = elementPosition - bodyRect - 150;

      window.scrollTo({
          top: offsetPosition,
          behavior: "smooth"
      }); 
      
      // targetRef.current.scrollIntoView({
      //   behavior: 'smooth'
      // });
    }
  }, [location.hash]);


  return (
    <div ref={targetRef} className={clsx(classes.root, className)} {...rest}>
      <SectionHeader
        title="Tuition"
        subtitle="Tuition includes all course materials as well as our counselor and teacher services."
        data-aos="fade-up"
      />
      <div className={classes.disclimer} data-aos="fade-up">
        <Typography variant="subtitle1" color="textSecondary">
          [Enrollment fees?]
        </Typography>
      </div>
      <Grid container spacing={isMd ? 4 : 2}>
        {data.map((item, index) => (
          <Grid item xs={12} sm={6} md={4} key={index} data-aos="fade-up">
            <Card className={classes.card}>
              <CardMedia className={classes.cardMedia}>
                <Image
                  {...item.image}
                  alt={item.title}
                  className={classes.image}
                  lazyProps={{
                    width: '100%',
                    height: '100%',
                  }}
                />
              </CardMedia>
              <CardContent className={classes.cardContent}>
                <Typography
                  color="primary"
                  variant="h5"
                  className={classes.fontWeightBold}
                >
                  {item.title}
                </Typography>
                <div className={classes.priceCta}>
                  <Typography
                    color="textPrimary"
                    variant="h6"
                  >
                    {item.price}
                  </Typography>
                </div>
              </CardContent>
            </Card>
          </Grid>
        ))}

      </Grid>
    </div>
  );
};

Products.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
  /**
   * data to be rendered
   */
  data: PropTypes.array.isRequired,
};

export default Products;
