import React, { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useMediaQuery } from '@material-ui/core';
import { SectionHeader } from 'components/molecules';
import { CardBase, DescriptionListIcon, CardProduct } from 'components/organisms';
import { Image } from 'components/atoms';

const useStyles = makeStyles(theme => ({
  root: {},
  card: {
    borderBottom: `5px solid ${theme.palette.secondary.main}`,
  },
  content: {
    padding: theme.spacing(0, 3),
  },
  image: {
    objectFit: 'cover',
    borderRadius: theme.spacing(0, 0, 0, 20),
  },
}));

const HighSchoolResearchCourses = props => {
  const { className, ...rest } = props;
  const classes = useStyles();

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const location = useLocation();
  const targetRef = useRef();

  useEffect(() => {
    if (targetRef && location.hash === '#high-school-research-classes') { 
      const bodyRect = document.body.getBoundingClientRect().top;
      const elementPosition = targetRef.current.getBoundingClientRect().top;
      const offsetPosition = elementPosition - bodyRect - 150;

      window.scrollTo({
          top: offsetPosition,
          behavior: "smooth"
      });   

      // targetRef.current.scrollIntoView({
      //   block: "start",
      //   behavior: 'smooth'
      // });
    }
  }, [location.hash]);

  return (
    <div ref={targetRef} className={clsx(classes.root, className)} {...rest}>
      <CardProduct liftUp noShadow variant="outlined" className={classes.card}
          mediaContent={
              <Image
                src="/images/academics/research.png"
                srcSet="/images/academics/research.png"
                lazyProps={{ width: '100%', height: '100%' }}
                className={ classes.image }
              />
          }
          cardContent={
            <DescriptionListIcon
              className={classes.content}
              titleVariant='h3'
              title="High School Research Classes"
              subtitleVariant='h6'
              subtitle={
                <span>
                  Englewood Online High School proudly announces our very own high school research courses to prepare students 
                  to excel and thrive in higher institutions of learning. These semester-long courses consist of rigorous lessons 
                  on academic writing and research methodologies, as well as one-on-one mentoring toward a topic area of study. 
                  Our mentors, all experts in their field, come from respected academic institutions across the country. 
                  The course columnates in the student crafting a professional research paper or poster. 
                  As they conduct their projects, students will learn valuable skills such as fact-checking, critical thinking, 
                  time management, and problem solving, all while working with a mentor that is an expert in their field. 
                  Students will gain valuable insight into their discipline of choice, which will prepare them for pursuing that 
                  discipline in college and in their future careers.
                  <br />
                  <br />
                  This program doesn’t end once the student has completed their research. 
                  Once the paper or poster is complete, we guide our students through the process of getting their work published, 
                  whether that is in an international conference, or a well-known scholarly journal. 
                  Additionally, we submit student’s work for consideration for a number of awards. 
                  By the end of this course, Englewood High School students will have experienced firsthand the fulfillment and 
                  knowledge that comes from engaging in scholarly research, as well as a publication, presentation, 
                  or award that will help them stand out on future college applications.
                </span>
              }
              align="center"
              subtitleProps={{ align: 'left' }}
            />
          }
        >
      </CardProduct>
    </div>
  );
};

HighSchoolResearchCourses.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
  
};

export default HighSchoolResearchCourses;
