import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useMediaQuery, Grid } from '@material-ui/core';
import { DescriptionListIconWithHTitle } from 'components/organisms';

const useStyles = makeStyles(theme => ({
  root: {},
}));

const AthleteAndPerformerProgram = props => {
  const { data, className, ...rest } = props;
  const classes = useStyles();

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Grid container spacing={isMd ? 4 : 2}>
        {data.map((item, index) => (
          <Grid key={index} item xs={12} sm={4} data-aos={'fade-up'}>
            <DescriptionListIconWithHTitle
              title={item.title}
              subtitle={item.subtitle}
              iconsrc={item.icon}
              align="left"
            />
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

AthleteAndPerformerProgram.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
  /**
   * data to be rendered
   */
  data: PropTypes.array.isRequired,
};

export default AthleteAndPerformerProgram;
