export const highSchoolPricings = [
  {
    title: 'Athlete & Performer',
    pricePrefix1: 'Full academic year',
    price: '$5,000',
    features: [
    ],
    pricePrefix2: 'Single semester',
    price2: '$2,750',
  },
  {
    title: 'Standard',
    pricePrefix1: 'Full academic year',
    price: '$6,000',
    features: [
    ],
    pricePrefix2: 'Single semester',
    price2: '$3,500',
  },
  {
    title: 'Gifted',
    pricePrefix1: 'Full academic year',
    price: '$8,000',
    features: [
      'Includes a research course* without additional cost',
    ],
    pricePrefix2: 'Single semester',
    price2: '$4,500',
  },
];


export const featuredProducts = [
  {
    image: {
      src: '/images/photos/admissions/admisson_02.jpg',
      srcSet: '/images/photos/admissions/admisson_02.jpg',
    },
    price: 'Independent holistic & healthy café | Simple approach to Australian cafe culture in a relaxed environment',
    title: 'Athlete program',
    rating: 5,
  },
  {
    image: {
      src: '/images/photos/admissions/admisson_03.jpg',
      srcSet: '/images/photos/admissions/admisson_03.jpg',
    },
    price: 'Independent holistic & healthy café | Simple approach to Australian cafe culture in a relaxed environment',
    title: 'Standard program',
    rating: 4,
  },
  {
    image: {
      src: '/images/photos/admissions/admisson_04.jpg',
      srcSet: '/images/photos/admissions/admisson_04.jpg',
    },
    price: 'Independent holistic & healthy café | Simple approach to Australian cafe culture in a relaxed environment',
    title: 'Gifted program',
    rating: 5,
  },
];

export const mostViewedArticles = [
  {
    cover: {
      src: '/images/photos/admissions/admisson_02.jpg',
      srcSet: '/images/photos/admissions/admisson_02.jpg',
    },
    title: 'Athlete program',
    subtitle:
      'Independent holistic & healthy café | Simple approach to Australian cafe culture in a relaxed environment',
  },
  {
    cover: {
      src: '/images/photos/admissions/admisson_03.jpg',
      srcSet: '/images/photos/admissions/admisson_03.jpg',
    },
    title: 'Standard program',
    subtitle:
      'Get your favourite adidas shoes, clothing & accessories at the official website! adidas Training.',
  },
  {
    cover: {
      src: '/images/photos/admissions/admisson_04.jpg',
      srcSet: '/images/photos/admissions/admisson_04.jpg',
    },
    title: 'Gifted program',
    subtitle:
      'Be Your Best Every Time With Nike Shoes And Clothing. Shop Online. The Official Website. Home Of Everything Nike. Shop The Latest Releases Today! Types: Shoes, Tops.',
  },
];