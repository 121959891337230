import React, { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Avatar } from '@material-ui/core';
import { SectionHeader } from 'components/molecules';
import { DescriptionListIcon, DescriptionListIconWithHTitle, CardJobMinimal } from 'components/organisms';

const useStyles = makeStyles(theme => ({
  root: {},
  descriptionListIcon: {
    marginBottom: theme.spacing(1),
  },
  listHeader: {
    border: `solid 1px rgba(43, 41, 45, 0.2)`,
    borderRadius: theme.spacing(1),
    padding: theme.spacing(3, 0, 1, 0),
    background: "#F0F3FA",
  },
  avatar: {
    background: 'transparent',
    width: 50,
    height: 50,
    borderRadius: theme.spacing(1),
  },
  marginTop: {
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(5),
    },
  },
  fontWeight400: {
    fontWeight: 400,
  },
}));

const MiddleSchoolCurriculum = props => {
  const { data, className, ...rest } = props;
  const classes = useStyles();

  const location = useLocation();
  const targetRef = useRef();

  useEffect(() => {
    if (targetRef && location.hash ==='#middle-school-curriculum') { 
      const bodyRect = document.body.getBoundingClientRect().top;
      const elementPosition = targetRef.current.getBoundingClientRect().top;
      const offsetPosition = elementPosition - bodyRect - 150;

      window.scrollTo({
          top: offsetPosition,
          behavior: "smooth"
      }); 
      
      // targetRef.current.scrollIntoView({
      //   behavior: 'smooth'
      // });
    }
  }, [location.hash]);

  return (
    <div ref={targetRef} className={clsx(classes.root, className)} {...rest}>
      <SectionHeader
        title={
          <span>Middle School Curriculum</span>
        }
        subtitle={
          <span>
            Our middle school curriculum is designed very carefully to meet the various academic and personal needs of our students. 
            We offer ELA, Math, Science, History and Social science, World languages and a selection of electives. 
            At Englewood Online High School, middle school students receive state of the art virtual lessons powered by Accelerate Education, 
            as well as  personal attention from Englewood Online High School teachers and advisors. 
            Students and parents check up on their academic progress with our experienced advisors and 
            receive extra help in live learning sessions with highly qualified teachers.
          </span>
        }
        fadeup="true"
        titleVariant="h3"
      />
      <Grid container spacing={4}>
        <Grid item xs={12} sm={6} md={4}>
          <div className={classes.listHeader} data-aos="fade-up">
            <DescriptionListIcon
              icon={
                <Avatar src={data.english.icon} className={classes.avatar} />
              }
              title={data.english.title}
              subtitle=''
              align="center"
              className={classes.descriptionListIcon}
              data-aos="fade-up"
            />
          </div>
          <br />
          <Grid container spacing={2}>
            {data.english.items.map((item, index) => (
              <Grid item xs={12} key={index} data-aos="fade-up">
                <CardJobMinimal
                  title={item.title}
                  titleClasses={classes.fontWeight400}
                  subtitle={`${item.grades}`}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <div className={classes.listHeader} data-aos="fade-up">
            <DescriptionListIcon
              icon={
                <Avatar src={data.mathematics.icon} className={classes.avatar} />
              }
              title={data.mathematics.title}
              subtitle=''
              align="center"
              className={classes.descriptionListIcon}
              data-aos="fade-up"
            />
          </div>
          <br />
          <Grid container spacing={2}>
            {data.mathematics.items.map((item, index) => (
              <Grid item xs={12} key={index} data-aos="fade-up">
                <CardJobMinimal
                  title={item.title}
                  titleClasses={classes.fontWeight400}
                  subtitle={`${item.grades}`}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <div className={classes.listHeader} data-aos="fade-up">
            <DescriptionListIcon
              icon={
                <Avatar src={data.science.icon} className={classes.avatar} />
              }
              title={data.science.title}
              subtitle=''
              align="center"
              className={classes.descriptionListIcon}
              data-aos="fade-up"
            />
          </div>
          <br />
          <Grid container spacing={2}>
            {data.science.items.map((item, index) => (
              <Grid item xs={12} key={index} data-aos="fade-up">
                <CardJobMinimal
                  title={item.title}
                  titleClasses={classes.fontWeight400}
                  subtitle={`${item.grades}`}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6} md={4} className={classes.marginTop}>
          <div className={classes.listHeader} data-aos="fade-up">
            <DescriptionListIcon
              icon={
                <Avatar src={data.languages.icon} className={classes.avatar} />
              }
              title={data.languages.title}
              subtitle=''
              align="center"
              className={classes.descriptionListIcon}
              data-aos="fade-up"
            />
          </div>
          <br />
          <Grid container spacing={2}>
            {data.languages.items.map((item, index) => (
              <Grid item xs={12} key={index} data-aos="fade-up">
                <CardJobMinimal
                  title={item.title}
                  titleClasses={classes.fontWeight400}
                  subtitle={`${item.grades}`}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6} md={4} className={classes.marginTop}>
          <div className={classes.listHeader} data-aos="fade-up">
            <DescriptionListIcon
              icon={
                <Avatar src={data.hss.icon} className={classes.avatar} />
              }
              title={data.hss.title}
              subtitle=''
              align="center"
              className={classes.descriptionListIcon}
              data-aos="fade-up"
            />
          </div>
          <br />
          <Grid container spacing={2}>
            {data.hss.items.map((item, index) => (
              <Grid item xs={12} key={index} data-aos="fade-up">
                <CardJobMinimal
                  title={item.title}
                  titleClasses={classes.fontWeight400}
                  subtitle={`${item.grades}`}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6} md={4} className={classes.marginTop}>
          <div className={classes.listHeader} data-aos="fade-up">
            <DescriptionListIcon
              icon={
                <Avatar src={data.electives.icon} className={classes.avatar} />
              }
              title={data.electives.title}
              subtitle=''
              align="center"
              className={classes.descriptionListIcon}
              data-aos="fade-up"
            />
          </div>
          <br />
          <Grid container spacing={2}>
            {data.electives.items.map((item, index) => (
              <Grid item xs={12} key={index} data-aos="fade-up">
                <CardJobMinimal
                  title={item.title}
                  titleClasses={classes.fontWeight400}
                  subtitle={`${item.grades}`}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

MiddleSchoolCurriculum.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
  /**
   * data to be rendered
   */
  data: PropTypes.object.isRequired,
};

export default MiddleSchoolCurriculum;
