import React from 'react';
import { Switch, Redirect } from 'react-router-dom';

import { RouteWithLayout } from './common';
import { Main as MainLayout, Minimal as MinimalLayout } from './layouts';

import {
  Landing as LandingView,
  HowItWorks as HowItWorksView,
  Admissions as AdmissionsView,
  Academics as AcademicsView,
  StudentLife as StudentLifeView,
  About as AboutView,
  NotFoundCover as NotFoundCoverView,
} from './views';

const Routes = () => {
  return (
    <Switch>
      <Redirect exact from="/" to="/home" />
      <RouteWithLayout
        component={LandingView}
        exact
        layout={MainLayout}
        path="/home"
      />
      <RouteWithLayout
        component={HowItWorksView}
        exact
        layout={MainLayout}
        path="/how-it-works"
      />
      <RouteWithLayout
        component={AdmissionsView}
        exact
        layout={MainLayout}
        path="/admissions"
      />
      <RouteWithLayout
        component={AcademicsView}
        exact
        layout={MainLayout}
        path="/academics"
      />
      <RouteWithLayout
        component={StudentLifeView}
        exact
        layout={MainLayout}
        path="/student-life"
      />
      <RouteWithLayout
        component={AboutView}
        exact
        layout={MainLayout}
        path="/about-us"
      />
      <RouteWithLayout
        component={NotFoundCoverView}
        exact
        layout={MinimalLayout}
        path="/not-found-cover"
      />
      <Redirect to="/not-found-cover" status="404" />
    </Switch>
  );
};

export default Routes;
