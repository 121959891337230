export const schedule3Programs = [
  {
    title: 'Golfer Jane in Athletic Program',
    title1: 'Golfer Jane',
    title2: 'Athletic Program',
    cover: {
      src: '/images/student-life/studentlife_3programs_1.jpg',
      srcSet: '/images/student-life/studentlife_3programs_1.jpg',
    },
    schedules: [
      {
        apm: 'AM',
        title: 'Yoga',
        time: '7:00',
      },
      {
        apm: 'AM',
        title: 'Biology',
        time: '8:00',
      },
      {
        apm: 'AM',
        title: 'English 10',
        time: '9:00',
      },
      {
        apm: 'AM',
        title: 'World History',
        time: '10:00',
      },
      {
        apm: 'AM',
        title: 'Geometry',
        time: '11:00',
      },
      {
        apm: 'PM',
        title: 'Lunch',
        time: '12:00',
      },
      {
        apm: 'PM',
        title: 'Golf swing clinic with her Golf instructor',
        time: '1:30',
      },
      {
        apm: 'PM',
        title: 'Golf practice rounding',
        time: '3:30',
      },
    ]
  },
  {
    title: 'Storyteller Sam in Standard Program',
    title1: 'Storyteller Sam',
    title2: 'Standard Program',
    cover: {
      src: '/images/student-life/studentlife_3programs_2.jpg',
      srcSet: '/images/student-life/studentlife_3programs_2.jpg',
    },
    schedules: [
      {
        apm: 'AM',
        title: 'Creative Writing',
        time: '9:00',
      },
      {
        apm: 'AM',
        title: 'World History Honors',
        time: '10:00',
      },
      {
        apm: 'AM',
        title: 'Chemistry',
        time: '11:00',
      },
      {
        apm: 'PM',
        title: 'Lunch',
        time: '12:00',
      },
      {
        apm: 'PM',
        title: 'Spanish II',
        time: '1:00',
      },
      {
        apm: 'PM',
        title: 'Pre calculus',
        time: '2:00',
      },
      {
        apm: 'PM',
        title: 'Literary Journal Club Meeting',
        time: '3:00',
      },
      {
        apm: 'PM',
        title: 'Work on short story for a creative writing contest',
        time: '4:00',
      },
    ]
  },
  {
    title: 'Smarty John in Gifted Program',
    title1: 'Smarty John',
    title2: 'Gifted Program',
    cover: {
      src: '/images/student-life/studentlife_3programs_3.jpg',
      srcSet: '/images/student-life/studentlife_3programs_3.jpg',
    },
    schedules: [
      {
        apm: 'AM',
        title: 'Independent Research on Recyclable energy with her mentor',
        time: '9:00',
      },
      {
        apm: 'AM',
        title: 'AP Environmental Science',
        time: '11:00',
      },
      {
        apm: 'PM',
        title: 'Lunch',
        time: '12:00',
      },
      {
        apm: 'PM',
        title: 'AP US History',
        time: '1:00',
      },
      {
        apm: 'PM',
        title: 'AP French Language and culture',
        time: '2:00',
      },
      {
        apm: 'PM',
        title: 'AP English Language and Composition',
        time: '3:00',
      },
      {
        apm: 'PM',
        title: 'AP Calculus AB',
        time: '4:00',
      },
      {
        apm: 'PM',
        title: 'Debate club meeting to prepare a Lincoln Douglas competition',
        time: '5:00',
      },
    ]
  },
];

export const collegeCounseling = [
  {
    cover: {
      src: '/images/student-life/studentlife_collegecounseling_1.jpg',
      srcSet: '/images/student-life/studentlife_collegecounseling_1.jpg',
    },
    title: 'Personal Support',
    subtitle:
      'Although our classes are asynchronous, Englewood Online High School provides layers of personal support to make sure our  students are on the right academic path. Our friendly and  experienced academic advisors communicate with students regularly through email and one-on-one virtual conferences to discuss their personal academic plan. Adolescence is a very important time to explore life and establish self identity. Englewood Online High School is determined to guide students and help them blossom into happy, fulfilled adults.',
  },
  {
    cover: {
      src: '/images/student-life/studentlife_collegecounseling_2.jpg',
      srcSet: '/images/student-life/studentlife_collegecounseling_2.jpg',
    },
    title: "College Bound Counseling",
    subtitle:
    `As soon as students enroll in 9th grade, our college counselors start to provide students with college bound counseling to help guide them through the often tumultuous admissions process. Taking into account a student's grades and future goals, our counselors collaborate with students and their parents to determine a prospective major, academic plan, and school list. Through the series of college bound counseling sessions, students pick the right courses, activities, and awards that will help them stand out in college admissions.`,
  },
]; 

export const clubsAndExtracurricularActivities = [
  {
    label: 'FULLY RESPONSIVE',
    title: 'Academic Clubs',
    description:
      `For students looking for clubs that will challenge them academically outside of the classroom, we offer a variety of academic clubs. From Chemistry, Physics, to engineering, we have extracurriculars with bright students led by passionate teachers.`,
    description2:
      `Through our academic clubs, Englewood Online High School encourages our students to compete in national and international competitions, and provides support and strategy for students choosing to enter these competitions.`,
    illustration: '/images/student-life/studentlife_academicclubs.png',
  },
  {
    label: 'FULLY RESPONSIVE',
    title: 'Community Service',
    description2:
      `We encourage our students to participate in community service, whether that is volunteering for a local animal shelter, or participating in an online fundraiser. We believe that community service can inspire students to achieve great things. In addition to having numerous clubs dedicated to community service, we also require that students complete at least one community service project per academic year`,
    illustration: '/images/student-life/studentlife_communityservice.png',
  },
  {
    label: 'FULLY RESPONSIVE',
    title: 'Activities',
    description:
      'For students in our gifted program, we offer a variety of clubs for students to discover their passions outside of the virtual classroom. These clubs offer a relaxed, fun environment, with weekly meetings led by a member of our faculty who has a passion for the topic. This passion deepens with multiple opportunities for students to socialize, bond, and participate in competitions related to the club. From a Chess Club to a Graphic Design club, students will be able to find whatever club suits their interests. And if for some reason that club isn’t there, students have the opportunity to propose their own clubs!',
    illustration: '/images/student-life/studentlife_activies.png',
  },
];