import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
} from '@material-ui/core';
import { Image } from 'components/atoms';
import { SectionHeader } from 'components/molecules';

const useStyles = makeStyles(theme => ({
  root: {},
  textWhite: {
    color: 'white',
  },
  image: {
    maxWidth: 400,
  },
}));

const FooterNewsletter = props => {
  const { data, className, ...rest } = props;
  const classes = useStyles();

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={6} container>
          <Image
            src="/images/illustrations/relax-in-sofa.svg"
            className={classes.image}
          />
        </Grid>
        <Grid item xs={12} sm={6} container alignItems="center">
          <div>
            <SectionHeader
              title={
                <span className={classes.textWhite}>
                  Gifted Program
                </span>
              }
              subtitle={
                <span className={classes.textWhite}>
                  At Englewood Academy, we understand that gifted students can often feel constrained in a brick and mortar school environment. 
                  Too often, talented students are forced to relearn material that they’ve already mastered, 
                  or sit through classes that move too slowly for them. 
                  Our gifted program is tailored to each individual student’s needs, 
                  providing challenging coursework and opportunities with gifted children in mind, 
                  such as independent study and guided research opportunities.
                </span>
              }
              fadeUp
              align="left"
            />
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

FooterNewsletter.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
};

export default FooterNewsletter;
