import React, { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, } from '@material-ui/core';
import { Image } from 'components/atoms';
import { SectionHeader } from 'components/molecules';
import { CardBase, DescriptionListIcon } from 'components/organisms';


const useStyles = makeStyles((theme) => ({
  root: {},
  disclimer: {
    borderRadius: theme.spacing(2),
    padding: theme.spacing(1 / 2, 1),
    background: theme.palette.white,
    display: 'inline-block',
  },
}));

const HighSchoolCurriculum = props => {
  const { data, className, ...rest } = props;
  const classes = useStyles();
  const location = useLocation();
  const targetRef = useRef();

  useEffect(() => {
    if (targetRef && location.hash === '#high-school-curriculum') { 
      const bodyRect = document.body.getBoundingClientRect().top;
      const elementPosition = targetRef.current.getBoundingClientRect().top;
      const offsetPosition = elementPosition - bodyRect - 150;

      window.scrollTo({
          top: offsetPosition,
          behavior: "smooth"
      });   

      // targetRef.current.scrollIntoView({
      //   block: "start",
      //   behavior: 'smooth'
      // });
    }
  }, [location.hash]);

  return (
    <div ref={targetRef} className={clsx(classes.root, className) } {...rest}>
      <SectionHeader
        title={
            <span>High School Curriculum</span>
        }
        subtitle='Our High School curriculum prepares students for life in a global, interconnected world. Students develp critical thinking skills and a strong work ethic that will help them through their college and career lives. We offer about 180 core courses, including 14 college board certified AP courses. Additionally, our wide range of elective courses  are not just academically focused, but provide an opportunity for our students to engage in career prep and experience programs. Lastly, we offer credit recovery courses to ensure students struggling or falling behind can get back on track.'
        fadeup="true"
        titleVariant="h3"
      />
      <div className={classes.disclimer} data-aos="fade-up">
        <Typography variant="subtitle1" color="textSecondary">
          
          * Number of subjects held
        </Typography>
      </div>
      <Grid container spacing={2}>
        {data.map((adv, index) => (
          <Grid
            key={index}
            item
            container
            alignItems="center"
            direction="column"
            xs={12}
            sm={6}
            md={3}
            data-aos="fade-up"
          >
            <CardBase
              liftUp
              variant="outlined"
              style={{ borderTop: `4px solid #CE9A1A` }}
            >
              <DescriptionListIcon
                icon={
                  <Image
                    src={adv.icon}
                    srcSet={adv.icon}
                  />
                }
                title={adv.title}
              />
            </CardBase>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

HighSchoolCurriculum.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
  /**
   * data to be rendered
   */
  data: PropTypes.array.isRequired,
};

export default HighSchoolCurriculum;
