export const big5reasons = [
  {
    cover: {
      src: '/images/landing/2_big5_1.jpg',
      srcSet: '/images/landing/2_big5_1.jpg',
    },
    title: 'Flexible and Safe Online Learning',
    subtitle: `Our  rigorous coursework is  available to students 24-7, from anywhere in the world. Students learn  based on their preferred schedules and academic needs, not ours.`,
    subtitle2: `With current uncertainty surrounding brick and mortar schooling, both students and parents can feel safe learning in the comfort of their homes. Some of our students  prefer to log in at 8 o’clock in the morning Monday through Friday and follow a conventional school system calendar. Career students like young entrepreneurs, student athletes or performers can study in the evening or work intensively two days a week to best fit their busy schedules. The choice is up to you!`,
    tags: ['Follow a Conventional School Schedule OR',
          'Set Your Own Personalized Schedule',
          'Stay Home Safely While still Getting a Quality Education'
    ],
  },
  {
    cover: {
      src: '/images/landing/2_big5_2.jpg',
      srcSet: '/images/landing/2_big5_2.jpg',
    },
    title: 'Quality Learning Experience',
    subtitle: `Englewood Online High School curriculums are powered by a premium provider, Accelerate Education, and created by our highly qualified in-house teachers.`,
    subtitle2: `All of our AP courses are fully approved by the College Board and fulfill common core requirements. We offer strong and enriching middle and high school curriculums that will prepare your child for a future in a global and connected world.`,
    tags: ['Premium Curriculum from Accelerate Education',
          'College Board-Approved AP courses',
          'Highly Qualified in-house teachers'
      ],
  },
  {
    cover: {
      src: '/images/landing/2_big5_3.jpg',
      srcSet: '/images/landing/2_big5_3.jpg',
    },
    title: 'One-on-One Attention',
    subtitle: `Although our classes are largely asynchronous, we ensure that our students have all the support they need through one-on-one live teaching and advising sessions. `,
    subtitle2: `When a student enrolls, they are automatically assigned a personalized academic advisor, with whom they meet weekly to discuss  and adjust their academic and personal goals. Additionally, high school students receive premier college counseling in order to prepare for applications and standardized tests. On top of our great quality virtual lessons, our teachers also offer office hours and tutoring, so no student feels left behind in their learning.`,
    tags: ['Live Teaching Sessions',
          'Academic Advising',
          'College Counseling'
      ],
  },
  {
    cover: {
      src: '/images/landing/2_big5_4.jpg',
      srcSet: '/images/landing/2_big5_4.jpg',
    },
    title: 'High School Research Programs - Way Beyond Ordinary',
    subtitle: `Englewood Online High Schools well equipped standard curriculums and even credit recovery courses to support struggling students as other reputable schools do.`,
    subtitle2: `However, Englewood Online High School believes that embarking on scholarly research early in one’s academic career opens the door to new and exciting possibilities, both in the college and beyond. That is why we have partnered with research mentors from top institutions, in a variety of disciplines, to ensure that our students have all the necessary tools to pursue scholarly research in high school.`,
    tags: ['Qualified Mentors',
          'All Research Disciplines ',
          'Publication Support for Prestigious Conferences & Journals'
      ],
  },
  {
    cover: {
      src: '/images/landing/2_big5_5.jpg',
      srcSet: '/images/landing/2_big5_5.jpg',
    },
    title: 'Clubs and Activities',
    subtitle: `Our students are engaged and learning inside and outside of the classroom through our variety clubs, sports, music, and  volunteer opportunities.`,
    subtitle2: `We offer specialized clubs run by  teachers who are both passionate and experienced in their fields. From math, science, and STEM clubs, to art, coding, writing, and sports, and music, we offer the same or better opportunities and experiences that students would  get in a brick and mortar school. We make sure that our students are both academically and extracurricularly enriched.`,
    tags: ['Clubs for Every Passion',
          'Engagement and Participation',
          'Enriching experience'
      ],
  },
];


export const gallery = [
  {
    
    src: '/images/photos/4_gallery_1_460.png',
    srcSet: '/images/photos/4_gallery_1.png',
    title: 'gallery',
    cols: 1,
  },
  {
    src: '/images/photos/4_gallery_2.png',
    srcSet: '/images/photos/4_gallery_2.png',
    title: 'gallery',
    cols: 3,
  },
  {
    src: '/images/photos/4_gallery_3.png',
    srcSet: '/images/photos/4_gallery_3.png',
    title: 'gallery',
    cols: 3,
  },
  {
    src: '/images/photos/4_gallery_4_460.png',
    srcSet: '/images/photos/4_gallery_4.png',
    title: 'gallery',
    cols: 1,
  },
];
